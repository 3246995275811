import "./App.scss"
import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom"
import { useSelector } from "react-redux"
import { useTypedSelector } from "./hooks/useTypedSelector"
import Sidebar from "./components/sidebar/Sidebar"
import Header from "./components/header/Header"
import SignIn from "./signin/SignIn"
import ForgotPassword from "./ForgotPassword/forgotpassword"
import CreatePassword from "./CreatePassword/CreatePassword"
import Verification from "./Verification/verification"
import UserProfile from "./components/userprofile/UserProfile"
import Dashboard from "./components/dashboard/Dashboard"
import UserOverview from "./components/dashboard/useroverview/UserOverview"
import EFTPayments from "./components/eftpayments/EFTPayments"
import CardPayments from "./components/cardpayments/CardPayments"
import RefundRequests from "./components/refund-requests/RefundRequests"
import ManageUsers from "./components/manageusers/ManageUsers"
import CreateUser from "./components/manageusers/CreateUser"
import UpdateUser from "./components/manageusers/UpdateUser"
import TimeClockDashboard from "./components/time-clock/TimeClockDashboard"
import TimeClockBilling from "./components/time-clock/TimeClockBilling"
import ClockedIn from "./components/time-clock/ClockedIn"
import ServiceStatus from "./components/servicestatus/ServiceStatus"
import RegionSelect from "./components/payment-portal/pages/1-region-select/RegionSelect"
import CustomerSelect from "./components/payment-portal/pages/2-customer-select/CustomerSelect"
import TransactionType from "./components/payment-portal/pages/3-transaction-type/TransactionType"
import ProvinceSelect from "./components/payment-portal/pages/4-province-select/ProvinceSelect"
import CurrencySelect from "./components/payment-portal/pages/5-currency-select/CurrencySelect"
import ProductPlan from "./components/payment-portal/pages/6-product-plan/ProductPlan"
import PaymentType from "./components/payment-portal/pages/7-payment-type/PaymentType"
import CardDetails from "./components/payment-portal/pages/8-card-details/CardDetails"
import ExistingCard from "./components/payment-portal/pages/9-existing-card/ExistingCard"
import UpdateCard from "./components/payment-portal/pages/10-update-card/UpdateCard"
import EFTUpload from "./components/payment-portal/pages/11-eft-upload/EFTUpload"
import LeadPayment from "./components/payment-portal/pages/zoho-flows/lead-payment/LeadPayment"
import ContactPayment from "./components/payment-portal/pages/zoho-flows/contact-payment/ContactPayment"
import OpportunityPayment from "./components/payment-portal/pages/zoho-flows/opportunity-payment/OpportunityPayment"
import CreateOpportunity from "./components/payment-portal/pages/create-opportunity/CreateOpportunity"
import CreateOpportunityQuebec from "./components/payment-portal/pages/create-opportunity/CreateOpportunityQuebec"
import CreateOpportunityQuebecDetails from "./components/payment-portal/pages/create-opportunity/CreateOpportunityQuebecDetails"
import PaymentSuccess from "./components/payment-portal/pages/end-flows/payment-success/PaymentSuccess"
import PaymentError from "./components/payment-portal/pages/end-flows/payment-error/PaymentError"
import RefundRequest from "./components/payment-portal/pages/refund-request/RefundRequest"
import RefundRequestSuccess from "./components/payment-portal/pages/refund-request/RefundRequestSuccess"
import RefundRequestError from "./components/payment-portal/pages/refund-request/RefundRequestError"
import UpgradeOpportunity from "./components/payment-portal/pages/upgrade-opportunity/UpgradeOpportunity"
import PaymentLink from "./components/payment-portal/pages/12-payment-link/PaymentLink"
import AdditionalPercentDashboard from "./components/additional-percent/AdditionalPercentDashboard"
import RenewOpportunity from "./components/payment-portal/pages/renew-opportunity/RenewOpportunity"
import axios from "axios"
import customSort from "./helpers/customSort"
import PropTypes from "prop-types"

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
  const {
    windowWidth,
    isRedirect,
    columns,
    setColumns,
    getEFTPayments,
    eftPaymentsData,
    setEftPaymentsData,
    eftPaymentsOriginalData,
    getRefundRequests,
    refundRequestsData,
    setRefundRequestsData,
    refundRequestsOriginalData,
    masterAdmin,
    currentPage,
    setCurrentPage,
    userId,
    financeManager,
    feTeamManager,
    pmTeamManager,
  } = rest
  if (isRedirect) {
    return <Redirect to="/" />
  }

  return (
    <Route
      {...rest}
      render={({ ...props }) =>
        authed === true ? (
          <Component
            {...props}
            exact
            windowWidth={windowWidth && windowWidth}
            columns={columns}
            setColumns={setColumns}
            getEFTPayments={getEFTPayments}
            eftPaymentsData={eftPaymentsData}
            setEftPaymentsData={setEftPaymentsData}
            eftPaymentsOriginalData={eftPaymentsOriginalData}
            getRefundRequests={getRefundRequests}
            refundRequestsData={refundRequestsData}
            setRefundRequestsData={setRefundRequestsData}
            refundRequestsOriginalData={refundRequestsOriginalData}
            masterAdmin={masterAdmin}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            userId={userId}
            financeManager={financeManager}
            feTeamManager={feTeamManager}
            pmTeamManager={pmTeamManager}
          />
        ) : (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  authed: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.object,
  }),
}

function App() {
  const [masterAdmin, setMasterAdmin] = useState(false)
  const [admin, setAdmin] = useState(false)
  const [frontendSales, setFrontendSales] = useState(false)
  const [, setPerformanceManager] = useState(false)
  const [financeManager, setFinanceManager] = useState(false)
  const [feTeamManager, setFeTeamManager] = useState(false)
  const [pmTeamManager, setPmTeamManager] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [viewStatus, setViewStatus] = useState("hidden")
  const [access, setAccess] = useState([])

  const store = useTypedSelector((store) => store.userData.show)
  const userLoggedIn = useSelector((store) => store.userData.userLoggedIn)
  const userProfile = useSelector((store) => store.userData.userInformation)

  const contentContainerRef = useRef(null)

  const columnsArr = new Array(16).fill(true)
  const [columns, setColumns] = useState(columnsArr)

  useEffect(() => {
    const callback = function (e) {
      setWindowWidth(e.target.innerWidth)
    }
    window.addEventListener("resize", callback)
    return () => window.removeEventListener("resize", callback)
  }, [])

  const handlerOpenSidebar = useCallback(() => {
    setViewStatus("")
  }, [])
  const handlerClosedSidebar = useCallback(() => {
    setViewStatus("hidden")
  }, [])

  // USER PROFILE TYPE
  useEffect(() => {
    setMasterAdmin(userProfile.type === "MASTER")
    setAdmin(userProfile.type === "ADMIN")
    setFrontendSales(userProfile.type === "FRONTEND SALES")
    setPerformanceManager(userProfile.type === "PERFORMANCE MANAGER")
    setFinanceManager(userProfile.type === "FINANCE MANAGER")
    setFeTeamManager(userProfile.type === "FE TEAM MANAGER")
    setPmTeamManager(userProfile.type === "PM TEAM MANAGER")
  }, [userProfile])

  useEffect(() => {
    if (!userLoggedIn) {
      setMasterAdmin(false)
      setAdmin(false)
      setFrontendSales(false)
      setPerformanceManager(false)
      setFinanceManager(false)
      setFeTeamManager(false)
      setPmTeamManager(false)
    }
  }, [userLoggedIn])

  // EFT PAYMENTS
  const [eftPaymentsData, setEftPaymentsData] = useState([])
  const [eftPaymentsOriginalData, setEftPaymentsOriginalData] = useState()

  const getEFTPayments = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin/v1/eft-payment/get`)
      .then(async (res) => {
        await res.data.data.map(
          (payment) =>
          (payment.salesperson =
            payment.admin.first_name + " " + payment.admin.last_name)
        )

        await res.data.data.map(
          (payment) =>
          (payment.date =
            payment.createdAt.substring(0, 10) +
            " " +
            payment.createdAt.substring(11, 19))
        )

        await res.data.data.reverse()

        setEftPaymentsData(
          res.data.data.slice().sort((a, b) => {
            if (a.eft_status === "PENDING" && b.eft_status === "PENDING") {
              if (a.id > b.id) {
                return -1
              }
            } else if (a.eft_status === "PENDING") {
              return -1
            } else {
              return 1
            }
          })
        )
        setEftPaymentsOriginalData(
          res.data.data.slice().sort((a, b) => {
            if (a.eft_status === "PENDING" && b.eft_status === "PENDING") {
              if (a.id > b.id) {
                return -1
              }
            } else if (a.eft_status === "PENDING") {
              return -1
            } else {
              return 1
            }
          })
        )
      })
      .catch((err) => console.log(err))
  }

  // REFUND REQUESTS
  const [refundRequestsData, setRefundRequestsData] = useState([])
  const [refundRequestsOriginalData, setRefundRequestsOriginalData] = useState()
  const [currentPage, setCurrentPage] = useState(1)

  const getRefundRequests = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}admin/v1/refund-request/get?skip=${(currentPage - 1) * 50
        }`
      )
      .then(async (res) => {
        // await res.data.data.reverse()

        const valuesArr = userProfile.admins_features.map((item) => item.value)

        setRefundRequestsData(
          res.data.data
            .slice()
            .filter((request) => valuesArr.includes(request.country))
            .sort((a, b) => customSort(a) - customSort(b))
        )
        setRefundRequestsOriginalData(
          res.data.data
            .slice()
            .filter((request) => valuesArr.includes(request.country))
            .sort((a, b) => customSort(a) - customSort(b))
        )
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (userLoggedIn) {
      getEFTPayments()
    }
  }, [userLoggedIn])

  useEffect(() => {
    if (userLoggedIn && userProfile.admins_features.length > 1) {
      getRefundRequests()
    }
  }, [userLoggedIn, userProfile.admins_features, currentPage])

  useEffect(() => {
    const values = userProfile.admins_features.map((item) => item.value)

    setAccess(values)
  }, [userProfile])

  return (
    <div className={`${store ? "flex" : undefined}`}>
      <Router>
        <Sidebar
          masterAdmin={masterAdmin}
          admin={admin}
          viewStatus={viewStatus}
          closeSidebar={handlerClosedSidebar}
          access={access}
          financeManager={financeManager}
        />
        <div className={store ? "flex-col w-full pl-0 md:pl-255" : undefined}>
          <Header
            openSidebar={handlerOpenSidebar}
            closeSidebar={handlerClosedSidebar}
          />
          <div
            ref={contentContainerRef}
            className={`bg-F3F4F6 relative md-max:pr-4 content__container min-h-screen`}
            onClick={handlerClosedSidebar}
          >
            <Switch>
              {/* GENERAL ROUTES */}
              <Route exact path="/" component={SignIn} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />
              <Route exact path="/createpassword" component={CreatePassword} />
              <Route exact path="/verification" component={Verification} />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/account"
                component={UserProfile}
              />

              {/* DASHBOARD */}
              <PrivateRoute
                authed={userLoggedIn && !frontendSales}
                exact
                path="/dashboard"
                component={Dashboard}
                columns={columns}
                setColumns={setColumns}
                windowWidth={windowWidth}
              />
              <Route exact path="/user-overview" component={UserOverview} />

              {/* PAYMENT PORTAL */}
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/region-select"
                component={RegionSelect}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/customer-select"
                component={CustomerSelect}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/transaction-type"
                component={TransactionType}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/province-select"
                component={ProvinceSelect}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/currency-select"
                component={CurrencySelect}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/product-plan"
                component={ProductPlan}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/payment-type"
                component={PaymentType}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/card-details"
                component={CardDetails}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/existing-card"
                component={ExistingCard}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/update-card"
                component={UpdateCard}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/eft-upload"
                component={EFTUpload}
                getEFTPayments={getEFTPayments}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/payment-link"
                component={PaymentLink}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/lead-payment/:region/:lead_id"
                component={LeadPayment}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/contact-payment/:region/:contact_id"
                component={ContactPayment}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/opportunity-payment/:region/:contact_id/:opportunity_id"
                component={OpportunityPayment}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/create-opportunity"
                component={CreateOpportunity}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/create-opportunity/quebec"
                component={CreateOpportunityQuebec}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-portal/create-opportunity/quebec-details"
                component={CreateOpportunityQuebecDetails}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-success"
                component={PaymentSuccess}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/payment-error"
                component={PaymentError}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/refund-request/:region/:contact_id/:opportunity_id"
                component={RefundRequest}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/refund-request-success"
                component={RefundRequestSuccess}
                getRefundRequests={getRefundRequests}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/refund-request-error"
                component={RefundRequestError}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/upgrade-opportunity/:region/:contact_id/:opportunity_id"
                component={UpgradeOpportunity}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/renew-opportunity/:region/:contact_id/:opportunity_id"
                component={RenewOpportunity}
              />

              {/* EFT PAYMENTS */}
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/eft-payments"
                component={EFTPayments}
                eftPaymentsData={eftPaymentsData}
                setEftPaymentsData={setEftPaymentsData}
                eftPaymentsOriginalData={eftPaymentsOriginalData}
                getEFTPayments={getEFTPayments}
              />

              {/* CARD PAYMENTS */}
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/card-payments"
                component={CardPayments}
              />

              {/* REFUND REQUESTS */}
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/refund-requests"
                component={RefundRequests}
                refundRequestsData={refundRequestsData}
                setRefundRequestsData={setRefundRequestsData}
                refundRequestsOriginalData={refundRequestsOriginalData}
                getRefundRequests={getRefundRequests}
                masterAdmin={masterAdmin}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />

              {/* MANAGE USERS */}
              <PrivateRoute
                authed={userLoggedIn && !frontendSales}
                exact
                path="/manage-users"
                component={ManageUsers}
              />
              <PrivateRoute
                authed={userLoggedIn && !frontendSales}
                exact
                path="/create-user"
                component={CreateUser}
              />
              <PrivateRoute
                authed={userLoggedIn && !frontendSales}
                exact
                path="/update-user/:user_id"
                component={UpdateUser}
              />

              {/* TIME CLOCK */}
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/time-clock"
                component={TimeClockDashboard}
                masterAdmin={masterAdmin}
                financeManager={financeManager}
                feTeamManager={feTeamManager}
                pmTeamManager={pmTeamManager}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/time-clock/clocked-in"
                component={ClockedIn}
              />
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/time-clock/:admin_id"
                component={TimeClockBilling}
                masterAdmin={masterAdmin}
                financeManager={financeManager}
                feTeamManager={feTeamManager}
                pmTeamManager={pmTeamManager}
              />

              {/* ADDITIONAL PERCENT */}
              <PrivateRoute
                authed={userLoggedIn}
                exact
                path="/additional-percent"
                component={AdditionalPercentDashboard}
              />

              {/* SERVICE STATUS */}
              <Route exact path="/service-status" component={ServiceStatus} />
              <Redirect from="*" to="/" />
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  )
}

export default App
