import { ChangeEvent, FC, useEffect, useState } from "react"
import UpdateLeadOwnerModal from "./components/UpdateLeadOwnerModal"
import UploadConfirmationModal from "./components/UploadConfirmationModal"
import AdditionalPercentModal from "../../components/additional-percent-modal/AdditionalPercentModal"
import ExpressPaymentModal from "../../components/express-payment-modal/ExpressPaymentModal"
import { ReactComponent as UploadScreenshot } from "../../../../assets/paymentCollection/upload-screenshot.svg"
import Layout from "../../components/layout/Layout"

interface PropTypes {
  getEFTPayments: () => void
}

const EFTUpload: FC<PropTypes> = ({ getEFTPayments }) => {

  const createOpportunitySkipped =
    sessionStorage.getItem("createOpportunitySkipped") === "Yes" ||
    (sessionStorage.getItem("province") === "Quebec" && sessionStorage.getItem("isCreateOpportunity") === "Yes")

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [file, setFile] = useState<FormData>(new FormData())
  const [fileName, setFileName] = useState("")
  const [amount, setAmount] = useState("")
  const [amountErrorMessage, setAmountErrorMessage] = useState(false)
  const [amountZeroMessage, setAmountZeroMessage] = useState(false)
  const [fileErrorMessage, setFileErrorMessage] = useState<boolean>(false)
  const [uploadConfirmationModalOpen, setUploadConfirmationModalOpen] =
    useState(false)
  const [updateLeadOwnerModalOpen, setUpdateLeadOwnerModalOpen] =
    useState(false)
  const [checkPaymentSuccess, setCheckPaymentSuccess] = useState("")

  const [isNoAddCharge, setIsNoAddCharge] = useState(false)

  const isPlanAddPercent =
    Boolean(sessionStorage.getItem("plan_add_percent")) && (sessionStorage.getItem("province") !== "Quebec"
      ? Number(amount) < Number(sessionStorage.getItem("total_program_price"))
      : sessionStorage.getItem("isFullPayment") !== "Yes")
  const [additionalPercentModalOpen, setAdditionalPercentModalOpen] = useState(false)

  const isExpressPaymentConfirmation =
  !createOpportunitySkipped &&
  sessionStorage.getItem("opportunity_add_percent") !== "" &&
  sessionStorage.getItem("transaction_type") === "Collection PM - Express Payment" &&
  sessionStorage.getItem("module") === "CONTACTS"
  const [expressPaymentModalOpen, setExpressPaymentModalOpen] = useState(false)

  const amountRemaining = Number(sessionStorage.getItem("amount_remaining")).toFixed(2);
  const opportunityAddPercent = Number(sessionStorage.getItem("opportunity_add_percent")).toFixed(2);
  const discountAmount = Number(Number(amountRemaining) * Number(opportunityAddPercent) / 100).toFixed(2);
  const discountedBalance = Number((Number(amountRemaining) - Number(discountAmount)).toFixed(2));

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile =
      event.currentTarget.files && event.currentTarget.files[0]

    if (!selectedFile) {
      setFileErrorMessage(true)
    } else if (!selectedFile.type.match("image.*")) {
      setFileErrorMessage(true)
    } else {
      const formData = new FormData()
      formData.append("files", selectedFile)
      setFileName(selectedFile.name)
      setFile(formData)
      setFileErrorMessage(false)
    }
  }

  const handleProceed = () => {
    if (!(sessionStorage.getItem("province") === "Quebec" && (sessionStorage.getItem("isCreateOpportunity") === "Yes" || sessionStorage.getItem("module") === "LEADS")) && !Number(amount)) {
      setAmountZeroMessage(true)
      return
    }

    if (sessionStorage.getItem("module") === "LEADS" || sessionStorage.getItem("createOpportunitySkipped") === "Yes") {
      if (+amount > Number(sessionStorage.getItem("total_program_price"))) {
        setAmountErrorMessage(true)
        return
      }

      setAmountErrorMessage(false)
      if (
        isPlanAddPercent && !isNoAddCharge
      ) {
        sessionStorage.setItem("isPlanAddPercent", "Yes")
        setAdditionalPercentModalOpen(true)
      } else {
        sessionStorage.setItem("isPlanAddPercent", "No")

        if (sessionStorage.getItem("module") === "LEADS") {
          setUpdateLeadOwnerModalOpen(true)
        }
        else {
          setUploadConfirmationModalOpen(true)
        }
      }
    }

    else if (sessionStorage.getItem("module") === "CONTACTS") {
      if (+amount > Number(sessionStorage.getItem("amount_remaining"))) {
        setAmountErrorMessage(true)
      } else {

        setAmountErrorMessage(false)
        
        if (isPlanAddPercent && !isNoAddCharge) {
          sessionStorage.setItem("isPlanAddPercent", "Yes")
          sessionStorage.setItem("isDiscount", "No")
          setAdditionalPercentModalOpen(true)
        } else if (isExpressPaymentConfirmation && +amount === discountedBalance) {
          sessionStorage.setItem("isPlanAddPercent", "No")
          sessionStorage.setItem("isDiscount", "Yes")
          setExpressPaymentModalOpen(true)
        } else {
          sessionStorage.setItem("isPlanAddPercent", "No")
          sessionStorage.setItem("isDiscount", "No")
          setUploadConfirmationModalOpen(true)
        }
      }
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem("check_payment_msg")) {
      setCheckPaymentSuccess("SUCCESS")
    }
    setTimeout(() => {
      setCheckPaymentSuccess("")
    }, 4000)
  }, [])

  useEffect(() => {

    if (fileName && (sessionStorage.getItem("province") === "Quebec" && (sessionStorage.getItem("isCreateOpportunity") === "Yes" || sessionStorage.getItem("module") === "LEADS"))) {
      setNextButtonDisabled(false)
      return
    }

    if (fileName && amount) {
      setNextButtonDisabled(false)
    } else {
      setNextButtonDisabled(true)
    }
  }, [file, amount])

  return (
    <Layout
      pageNumber={11}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <UpdateLeadOwnerModal
        updateLeadOwnerModalOpen={updateLeadOwnerModalOpen}
        setUpdateLeadOwnerModalOpen={setUpdateLeadOwnerModalOpen}
        setUploadConfirmationModalOpen={setUploadConfirmationModalOpen}
      />
      <UploadConfirmationModal
        uploadConfirmationModalOpen={uploadConfirmationModalOpen}
        setUploadConfirmationModalOpen={setUploadConfirmationModalOpen}
        file={file}
        amount={amount}
        getEFTPayments={getEFTPayments}
      />
      <AdditionalPercentModal
        additionalPercentModalOpen={additionalPercentModalOpen}
        setAdditionalPercentModalOpen={setAdditionalPercentModalOpen}
        nextModalOpen={sessionStorage.getItem("module") === "LEADS" ? setUpdateLeadOwnerModalOpen : setUploadConfirmationModalOpen}
      />
      <ExpressPaymentModal
        expressPaymentModalOpen={expressPaymentModalOpen}
        setExpressPaymentModalOpen={setExpressPaymentModalOpen}
        nextModalOpen={setUploadConfirmationModalOpen}
      />

      <div className="w-full h-full flex flex-col justify-evenly items-center">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          {sessionStorage.getItem("check_payment_msg") &&
            checkPaymentSuccess === "SUCCESS" && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative text-center mb-4">
                <span className="block sm:inline">
                  {sessionStorage.getItem("check_payment_msg")}
                </span>
              </div>
            )}
        </div>
        {isPlanAddPercent && (
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-6 relative flex items-start">
            <div className="mr-3 flex items-center h-5">
              <input
                type="checkbox"
                className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                checked={isNoAddCharge}
                onClick={() => setIsNoAddCharge(!isNoAddCharge)}
              />
            </div>
            <div className="text-sm">
              <label
                htmlFor="region_access"
                className="font-medium text-gray-700 select-none"
              >
                Are you collecting full payment after doctors appointment? (No additional charge will be applied if this is the case)
              </label>
            </div>
          </div>
        )}
        <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
          <p className="block text-sm font-medium text-gray-700 mb-2">
            Upload screenshot of EFT
          </p>
          <label
            htmlFor="file-upload"
            className="relative block cursor-pointer text-828282 bg-E0E0E0 border border-c4c4c4 pl-10 py-3 text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
          >
            <UploadScreenshot className="w-5 h-5 absolute fill-828282 left-3 top-3.5" />
            {fileName ? fileName : "Upload File"}
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={(e) => handleFileChange(e)}
            className="hidden"
          />
          {fileErrorMessage && (
            <div className="mt-2 text-red-600">
              Uploaded file is not an image. Please try again with an image.
            </div>
          )}
        </div>
        {(sessionStorage.getItem("province") === "Quebec" && (sessionStorage.getItem("isCreateOpportunity") === "Yes" || sessionStorage.getItem("module") === "LEADS")) ? null : (
          <div className="w-full px-4 sm:px-6 lg:px-8">
            <div className="block text-sm font-medium text-gray-700">
              Amount (
              {sessionStorage.getItem("currency") === "EUR"
                ? "€"
                : sessionStorage.getItem("currency") === "GBP"
                  ? "£"
                  : "$"}
              )
            </div>
            <input
              type="number"
              className="mt-2 rounded-md border-c4c4c4 h-12 bg-E0E0E0 flex items-center pl-2 text-828282 w-308 sm:w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                (e.target as HTMLInputElement).blur()
              }
            />
            {amountErrorMessage ? (
              sessionStorage.getItem("module") === "LEADS" || createOpportunitySkipped ? (
                <div className="mt-2 text-red-600">
                  Cannot process amount higher than the selected program price (
                  {`${sessionStorage.getItem("currency")}${Number(sessionStorage.getItem("total_program_price")).toFixed(2)}`}
                  )
                </div>
              ) : (
                sessionStorage.getItem("module") === "CONTACTS" && (
                  <div className="mt-2 text-red-600">
                    Cannot process amount higher than the remaining amount for this
                    opportunity (
                    {`${sessionStorage.getItem("currency")}${Number(
                      sessionStorage.getItem("amount_remaining")
                    ).toFixed(2)}`}
                    )
                  </div>
                )
              )
            ) : <></>}
            {amountZeroMessage && (
              <div className="mt-2 text-red-600">
                Please enter an amount greater than zero.
              </div>
            )}
          </div>
        )}
        {(isPlanAddPercent && !isNoAddCharge) && (
          <>
            <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
              <div>
                {" "}
                Additional percent charge ({sessionStorage.getItem("plan_add_percent")}%) will be applied as payment being collected is on a payment plan.
                Total program price will be{" "}
                {
                  sessionStorage.getItem("currency") === "EUR" ? "€"
                  : sessionStorage.getItem("currency") === "GBP" ? "£"
                  : "$"
                }
                {Number(sessionStorage.getItem("total_program_price_add_percent"))}
                {" "}
              </div>
            </div>
            <div className="text-center text-md text-amber-600 mt-2 px-4 sm:px-6 lg:px-8">
              <div>
                {" "}
                Discounted price is{" "}
                {
                  sessionStorage.getItem("currency") === "EUR" ? "€"
                  : sessionStorage.getItem("currency") === "GBP" ? "£"
                  : "$"
                }
                {Number(sessionStorage.getItem("total_program_price"))}
                {" "}
              </div>
            </div>
          </>
        )}
        {(isExpressPaymentConfirmation) && (
          <div className="text-center text-md text-amber-600 mt-4 px-4 sm:px-6 lg:px-8">
            <div>
              {" "}
              Discount will be applied for remaining balance and must be paid in full. Discounted remaining balance is
              {" "}
              {
                sessionStorage.getItem("currency") === "EUR" ? "€"
                : sessionStorage.getItem("currency") === "GBP" ? "£"
                : "$"
              }
              {discountedBalance.toFixed(2)}
              {" "}
              and discount amount is
              {" "}
              {
                sessionStorage.getItem("currency") === "EUR" ? "€"
                : sessionStorage.getItem("currency") === "GBP" ? "£"
                : "$"
              }
              {discountAmount}
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default EFTUpload
