import { FC } from "react"

interface PropTypes {
  pageNumber: number
}

const ProgressTitle: FC<PropTypes> = ({ pageNumber }) => {
  return (
    <div className="flex">
      <div className="block text-sm font-medium text-3fc69d mx-6">
        Select Region
      </div>
      <div
        className={`block text-sm font-medium ${
          pageNumber > 1 ? "text-3fc69d" : "text-c4c4c4"
        } mx-6`}
      >
        Customer Details
      </div>
      <div
        className={`block text-sm font-medium ${
          pageNumber > 2 ? "text-3fc69d" : "text-c4c4c4"
        } ml-7 mr-9`}
      >
        Transaction Type
      </div>
      {pageNumber === 11 ? (
        <div className="block text-sm font-medium text-3fc69d mx-6">
          EFT Upload
        </div>
      ) : pageNumber === 12 ? (
        <div className="block text-sm font-medium text-3fc69d mx-6">
          Payment Link
        </div>
      ) : (
        <div
          className={`block text-sm font-medium ${
            pageNumber > 7 ? "text-3fc69d" : "text-c4c4c4"
          } mx-6`}
        >
          Card Details
        </div>
      )}
    </div>
  );
}

export default ProgressTitle
