import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Layout from "../../components/layout/Layout"
import TransactionTypeSelectMenu from "./components/TransactionTypeSelectMenu"
import ProductPlanSearchMenu from "../../components/product-plan-search-menu/ProductPlanSearchMenu"
import spinnerNavy from "../../../../assets/common/spinnerNavy.svg"
import axios from "axios"

type ProductPlan = {
  Books_Item_Id: null
  Description: string
  Product_Name: string
  id: string
  Product_Active: boolean
  Unit_Price: number
  Product_Region: string
}

const TransactionType = () => {
  const history = useHistory()
  const transactionListLead = [{ id: "Sale FE" }]
  const transactionListContact = [
    { id: "Sale FE" },
    { id: "Sale PM" },
    { id: "Sale PCA" },
    { id: "Sale WMP" },
    { id: "Collection SPC" },
    { id: "Collection PM - Current" },
    { id: "Collection PM - Arrears" },
    { id: "Collection PM - Express Payment" },
    { id: "Renewal" },
  ]

  const productPlanArr = [
    {
      Books_Item_Id: "",
      Description: "",
      Product_Name: "",
      Product_Type: "",
      Unit_Price: 0,
      id: "",
    },
  ]

  const [nextButtonDisabled, setNextButtonDisabled] = useState(false)
  const [productPlanDropdownOpen, setProductPlanDropdownOpen] = useState(false)
  const [transactionList, setTransactionList] = useState(transactionListLead)
  const [transactionSelectedItem, setTransactionSelectedItem] = useState(
    "Select transaction type"
  )
  const [description, setDescription] = useState("")
  const [isProductPlanSelected, setIsProductPlanSelected] = useState(false)
  const [productPlanSelectedItem, setProductPlanSelectedItem] = useState("")
  const [selectProductPlanError, setSelectProductPlanError] = useState(false)
  const [searchingProductPlans, setSearchingProductPlans] = useState(false)
  const [queryError, setQueryError] = useState("")
  const [productPlanList, setProductPlanList] = useState(productPlanArr)

  useEffect(() => {
    if (transactionSelectedItem === "Select transaction type" || !description) {
      setNextButtonDisabled(true)
    } else {
      setNextButtonDisabled(false)
    }
  }, [transactionSelectedItem, description])

  useEffect(() => {
    if (sessionStorage.getItem("module") === "LEADS") {
      setTransactionList(transactionListLead)
    } else {
      setTransactionList(transactionListContact)
    }
  }, [])

  useEffect(() => {
    setTransactionSelectedItem("Select transaction type")
    setDescription("")
    setIsProductPlanSelected(false)
    setProductPlanSelectedItem("")
    setSelectProductPlanError(false)

    const removeKeys = ["transaction_type", "note"]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  const searchPlans = async () => {
    setSearchingProductPlans(true)
    setQueryError("")
    setSelectProductPlanError(false)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-product`,
        {
          country: sessionStorage.getItem("country"),
          searchString: productPlanSelectedItem,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setSearchingProductPlans(false)
        if (sessionStorage.getItem("country") === "UK") {
          setProductPlanList(
            data.filter((plan: ProductPlan) =>
              sessionStorage.getItem("currency") === "GBP"
                ? plan.Product_Region === "United Kingdom"
                : sessionStorage.getItem("currency") === "EUR"
                  ? plan.Product_Region === "Ireland"
                  : plan
            )
          )
        } else {
          setProductPlanList(data)
        }
        setProductPlanDropdownOpen(true)
      })
      .catch((err) => {
        setSearchingProductPlans(false)
        setProductPlanDropdownOpen(false)
        if (typeof err?.response?.data.message === "string") {
          setQueryError(err.response.data.message)
        }
      })
  }

  const handleProceed = () => {
    if (
      sessionStorage.getItem("country") === "CA" &&
      transactionSelectedItem === "Upgrade" &&
      !isProductPlanSelected
    ) {
      setSelectProductPlanError(true)
      return
    }

    sessionStorage.setItem("note", description)

    if (sessionStorage.getItem("module") === "LEADS") {
      if (sessionStorage.getItem("country") === "CA") {
        history.push("/payment-portal/province-select")
      }
      else if (sessionStorage.getItem("country") === "UK") {
        history.push("/payment-portal/currency-select")
      } else {
        history.push("/payment-portal/product-plan")
      }
    }

    if (sessionStorage.getItem("module") === "CONTACTS") {
      return history.push("/payment-portal/payment-type")
    }
  }

  return (
    <Layout
      pageNumber={3}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
    >
      <div className="mb-8 w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="transaction_type"
          className="block text-sm font-medium text-gray-700"
        >
          Transaction Type
        </label>
        <TransactionTypeSelectMenu
          transactionList={transactionList}
          transactionSelectedItem={transactionSelectedItem}
          setTransactionSelectedItem={setTransactionSelectedItem}
        />
      </div>
      {sessionStorage.getItem("country") === "CA" &&
        transactionSelectedItem === "Upgrade" && (
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
            <div className="flex">
              <label
                htmlFor="product_plan"
                className="block text-sm font-medium text-gray-700 mr-2"
              >
                Product Plan
              </label>
              {selectProductPlanError && (
                <span className="text-sm text-red-500 font-bold">
                  Please select product plan before proceeding
                </span>
              )}
              {searchingProductPlans && (
                <>
                  <img
                    width={24}
                    className="inline"
                    src={spinnerNavy}
                    alt="Loading..."
                  />
                  <span className="font-medium text-sm text-navy-theme">
                    Searching for plans...
                  </span>
                </>
              )}
              {queryError && (
                <span className="font-medium text-E84545 ml-2">
                  {queryError}
                </span>
              )}
            </div>
            <ProductPlanSearchMenu
              searchPlans={searchPlans}
              productPlanList={productPlanList}
              productPlanSelectedItem={productPlanSelectedItem}
              setProductPlanSelectedItem={setProductPlanSelectedItem}
              productPlanDropdownOpen={productPlanDropdownOpen}
              setProductPlanDropdownOpen={setProductPlanDropdownOpen}
              setIsProductPlanSelected={setIsProductPlanSelected}
            />
          </div>
        )}
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="description"
          className="block text-sm font-medium text-gray-700"
        >
          Description
        </label>
        <textarea
          className="mt-2 w-full h-36 bg-E0E0E0 border-c4c4c4 border rounded-md focus:border-c4c4c4 focus:outline-none focus:ring-0 font-medium text-828282 leading-5"
          style={{ resize: "none" }}
          placeholder="Enter details of payment"
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
    </Layout>
  )
}

export default TransactionType
