import { useEffect, useState, FC } from "react"
import { tableData } from "./tabledata/tabledata"
import ColumnsModal from "./modal/ColumnsModal"
import Users from "./users/Users"
import Pagination from "../common/Pagination"
import { CSVLink } from "react-csv"
import { handleSearch } from "../../helpers/handleSearch"
import { sortData } from "../../helpers/sortData"
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid"
import axios from "axios"
import { useDocTitle } from "../../hooks/useDocTitle"

interface MyProps {
  columns: boolean[]
  setColumns: (args: boolean[]) => void
}

type User = {
  client_id: string
  country: string
  email: string
  first_name: string
  hasTracking: boolean
  id: string
  index: number
  last_name: string
  mobile: string
  profileImage: string
  role: string
  type: string
  city: string
  createdById: string
  location: string
  residentialAddress: string
  shippingAddress: string
  updatedAt: string
  updatedById: string
  userId: string
  gaconnector: {
    gaconnectorfields1__GA_Client_ID: string
  }
}

const Dashboard: FC<MyProps> = ({ columns, setColumns }) => {
  useDocTitle("Dashboard - MHC Dashboard")
  const [open, setOpen] = useState<boolean>(false)
  const [users, setUsers] = useState<User[]>([])
  const [originalUsers, setOriginalUsers] = useState<User[]>([])
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const [auSelected, setAUSelected] = useState<boolean>(false)
  const [caSelected, setCASelected] = useState<boolean>(false)
  const [nzSelected, setNZSelected] = useState<boolean>(false)
  const [ukSelected, setUKSelected] = useState<boolean>(false)
  const [clientIDSelected, setClientIDSelected] = useState<boolean>(false)

  const fetchUsers = async (currentPage: number) => {
    setLoading(true)

    const {
      data: { data },
    } = await axios.get(
      `${process.env.REACT_APP_API_URL}admin/v1/user/get?skip=${currentPage}`
    )

    setUsers(data.rows)
    setOriginalUsers(data.rows)
    setTotalUsers(data.count)
    data.rows.map((user: User, index: number) => (user.index = index + 1))

    setLoading(false)
  }

  // SEARCH TABLE FUNCTION
  const [query, setQuery] = useState<string>("")
  const [searchParam] = useState<string[]>([
    "first_name",
    "last_name",
    "email",
    "mobile",
    "role",
    "type",
    "city",
    "country",
    "location",
    "residentialAddress",
    "shippingAddress",
    "createdById",
    "updatedById",
    "updatedAt",
    "id",
    "client_id",
  ])

  useEffect(() => {
    handleSearch(query, originalUsers, searchParam, setUsers)
  }, [query])

  // PAGINATION
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [usersPerPage] = useState<number>(10)

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)
  const pageNumbers: number[] = []

  for (let i = 1; i <= Math.ceil(totalUsers / usersPerPage); i++) {
    pageNumbers.push(i)
  }

  useEffect(() => {
    fetchUsers(currentPage * 10 - 10)
  }, [currentPage])

  // SORT BY CLICKING COLUMN HEADER
  const [order, setOrder] = useState<string>("ASC")
  const [sortingColumn, setSortingColumn] = useState<string>("")

  // GOOGLE SIGN IN AUTHENTICATION
  const oauthSignIn = () => {
    const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth"

    const form = document.createElement("form")
    form.setAttribute("method", "GET")
    form.setAttribute("action", oauth2Endpoint)

    const params = {
      client_id:
        "225603967722-hptu8o6dsa1hcgscg4bufjqoa8gmfeh2.apps.googleusercontent.com",
      redirect_uri: `${window.location.href}`,
      response_type: "token",
      scope: "https://www.googleapis.com/auth/analytics.readonly",
    }

    for (const p in params) {
      const input = document.createElement("input")
      input.setAttribute("type", "hidden")
      input.setAttribute("name", p)
      input.setAttribute("value", params[p as keyof typeof params])
      form.appendChild(input)
    }

    document.body.appendChild(form)
    form.submit()
  }

  // RETRIEVE ACCESS TOKEN FROM URL
  const urlString1 = window.location.hash.substr(1)?.split("=")
  const urlString2 = urlString1[1]?.split("&")
  const access_token = urlString2 && urlString2[0]

  useEffect(() => {
    if (access_token !== undefined) {
      localStorage.setItem("access_token", access_token)
    }
  }, [])

  // FILTERING
  const handleCheck = (value: string) => {
    switch (value) {
      case "AU":
        setAUSelected(true)
        setCASelected(false)
        setNZSelected(false)
        setUKSelected(false)
        break
      case "CA":
        setCASelected(true)
        setAUSelected(false)
        setNZSelected(false)
        setUKSelected(false)
        break
      case "NZ":
        setNZSelected(true)
        setAUSelected(false)
        setCASelected(false)
        setUKSelected(false)
        break
      case "UK":
        setUKSelected(true)
        setAUSelected(false)
        setCASelected(false)
        setNZSelected(false)
        break
      case "client_id":
        setClientIDSelected(true)
        break
    }
  }

  const clearFilters = () => {
    setUsers(originalUsers)
    setAUSelected(false)
    setCASelected(false)
    setNZSelected(false)
    setUKSelected(false)
    setClientIDSelected(false)
  }

  useEffect(() => {
    if (auSelected) {
      setUsers(originalUsers.filter((user) => user.country === "AU"))
    }
    if (caSelected) {
      setUsers(originalUsers.filter((user) => user.country === "CA"))
    }
    if (nzSelected) {
      setUsers(originalUsers.filter((user) => user.country === "NZ"))
    }
    if (ukSelected) {
      setUsers(originalUsers.filter((user) => user.country === "UK"))
    }
    if (clientIDSelected) {
      setUsers(
        users.filter(
          (user) =>
            user.client_id !== " " &&
            user.client_id !== null &&
            user.client_id !== undefined
        )
      )
    }
  }, [auSelected, caSelected, nzSelected, ukSelected, clientIDSelected])

  // USER MODAL LOGIC
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false)
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false)

  return (
    <>
      <div className="overflow-x-hidden">
        <div>
          <main>
            <ColumnsModal
              open={open}
              setOpen={setOpen}
              columns={columns}
              setColumns={setColumns}
            />

            <div className="flex justify-between">
              <div>
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none mb-4 mr-4"
                  onClick={() => setOpen(true)}
                >
                  Manage Columns
                </button>
                <CSVLink
                  data={users}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none mb-4 mr-4"
                >
                  Export to CSV
                </CSVLink>
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none mb-4 mr-4"
                  onClick={oauthSignIn}
                >
                  Access Analytics
                </button>

                <select
                  value="PLACEHOLDER"
                  onChange={(e) => {
                    if (e.target.value === "DELETE") {
                      setDeleteOpen(true)
                    } else {
                      setPasswordOpen(true)
                    }
                  }}
                  className="inline-flex items-center pl-3 pr-7 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none mb-4"
                >
                  <option value="PLACEHOLDER" hidden>
                    Actions
                  </option>
                  <option value="CHANGE_PASSWORD">Change Password</option>
                  <option value="DELETE">Delete</option>
                </select>
              </div>

              <div>
                <input
                  type="text"
                  className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>

            <p className="mt-1 mb-4 max-w-2xl text-sm text-gray-500">
              Sort columns by clicking the column header.
            </p>

            <div className="pt-4 lg:grid lg:grid-cols-8 lg:gap-x-8 xl:grid-cols-8">
              <aside>
                <div className="hidden lg:block">
                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none mb-4 mr-4"
                    onClick={clearFilters}
                  >
                    Clear filters
                  </button>
                  <form className="divide-y divide-gray-200 space-y-4">
                    <div>
                      <fieldset>
                        <legend className="block text-sm font-medium text-gray-900">
                          Region
                        </legend>
                        <div className="pt-4 space-y-2">
                          <div className="flex items-center">
                            <input
                              id="AU"
                              name="AU"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("AU")}
                              checked={auSelected}
                            />
                            <label
                              htmlFor="AU"
                              className="ml-3 text-sm text-gray-600"
                            >
                              AU
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="CA"
                              name="CA"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("CA")}
                              checked={caSelected}
                            />
                            <label
                              htmlFor="CA"
                              className="ml-3 text-sm text-gray-600"
                            >
                              CA
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="NZ"
                              name="NZ"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("NZ")}
                              checked={nzSelected}
                            />
                            <label
                              htmlFor="NZ"
                              className="ml-3 text-sm text-gray-600"
                            >
                              NZ
                            </label>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="UK"
                              name="UK"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("UK")}
                              checked={ukSelected}
                            />
                            <label
                              htmlFor="UK"
                              className="ml-3 text-sm text-gray-600"
                            >
                              UK
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="pt-4">
                      <fieldset>
                        <legend className="block text-sm font-medium text-gray-900">
                          Other fields
                        </legend>
                        <div className="pt-4 space-y-2">
                          <div className="flex items-center">
                            <input
                              id="client_id"
                              name="client_id"
                              type="radio"
                              className="border-transparent focus:border-transparent focus:ring-0 h-4 w-4 text-navy-theme border-gray-300"
                              onChange={() => handleCheck("client_id")}
                              checked={clientIDSelected}
                            />
                            <label
                              htmlFor="client_id"
                              className="ml-3 text-sm text-gray-600"
                            >
                              Has Client ID
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </form>
                </div>
              </aside>

              <div className="mt-6 lg:mt-0 lg:col-span-7 xl:col-span-7">
                <div className="flex flex-col">
                  <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-navy-theme">
                            <tr>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                                onClick={() =>
                                  setToggleSelectAll(
                                    (prevToggleSelectAll) =>
                                      !prevToggleSelectAll
                                  )
                                }
                              >
                                Selected
                              </th>
                              <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer"
                                onClick={() =>
                                  sortData(
                                    "number",
                                    order,
                                    users,
                                    "index",
                                    setUsers,
                                    setOrder,
                                    setSortingColumn
                                  )
                                }
                              >
                                <div className="flex items-center">
                                  <span className="pt-1">#</span>
                                  {sortingColumn === "index" ? (
                                    order === "DSC" ? (
                                      <ChevronUpIcon className={`h-6`} />
                                    ) : (
                                      <ChevronDownIcon className={`h-6`} />
                                    )
                                  ) : null}
                                </div>
                              </th>
                              {tableData.map((column, index) => (
                                <th
                                  scope="col"
                                  className={`${
                                    !columns[index] ? "sm:hidden" : null
                                  } px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider cursor-pointer`}
                                  onClick={() => {
                                    // Check which columns include numbers for sorting (ie. Mobile, Addresses, IDs, etc.)
                                    if (
                                      index === 3 ||
                                      index === 9 ||
                                      index === 10 ||
                                      index === 11 ||
                                      index === 12 ||
                                      index === 13 ||
                                      index === 14 ||
                                      index === 15
                                    ) {
                                      sortData(
                                        "number",
                                        order,
                                        users,
                                        "index",
                                        setUsers,
                                        setOrder,
                                        setSortingColumn
                                      )
                                    } else {
                                      sortData(
                                        "string",
                                        order,
                                        users,
                                        column.sort,
                                        setUsers,
                                        setOrder,
                                        setSortingColumn
                                      )
                                    }
                                  }}
                                  key={index}
                                >
                                  <div className="flex items-center">
                                    {column.name}
                                    {sortingColumn === column.sort ? (
                                      order === "DSC" ? (
                                        <ChevronUpIcon
                                          className={`${
                                            index === 9 ||
                                            index === 10 ||
                                            index === 11 ||
                                            index === 12
                                              ? "h-8"
                                              : "h-5"
                                          }`}
                                        />
                                      ) : (
                                        <ChevronDownIcon
                                          className={`${
                                            index === 9 ||
                                            index === 10 ||
                                            index === 11 ||
                                            index === 12
                                              ? "h-8"
                                              : "h-5"
                                          }`}
                                        />
                                      )
                                    ) : null}
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <Users
                              users={users}
                              loading={loading}
                              columns={columns}
                              originalUsers={users}
                              fetchUsers={fetchUsers}
                              deleteOpen={deleteOpen}
                              passwordOpen={passwordOpen}
                              setDeleteOpen={setDeleteOpen}
                              setPasswordOpen={setPasswordOpen}
                              toggleSelectAll={toggleSelectAll}
                              currentPage={currentPage}
                            />
                          </tbody>
                        </table>
                        <Pagination
                          elementsPerPage={usersPerPage}
                          totalElements={totalUsers}
                          paginate={paginate}
                          currentPage={currentPage}
                          type="DASHBOARD"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default Dashboard
