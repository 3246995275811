import { FC, Fragment, useState } from "react"
import { useHistory } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import spinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"

interface PaymentLinkConfirmationModalProps {
  paymentLinkConfirmationModalOpen: boolean
  setPaymentLinkConfirmationModalOpen: (args: boolean) => void
  amount: string
}

const PaymentLinkConfirmationModal: FC<
  PaymentLinkConfirmationModalProps
> = ({
  paymentLinkConfirmationModalOpen,
  setPaymentLinkConfirmationModalOpen,
  amount
}) => {
  const history = useHistory()
  const [isProcessing, setIsProcessing] = useState(false)

  const handleCancel = () => {
    setPaymentLinkConfirmationModalOpen(false)
    setIsProcessing(false)
  }

  const handlePayment = async () => {
    setIsProcessing(true)

    if (
      sessionStorage.getItem("createOpportunitySkipped") === "Yes"  &&
      (sessionStorage.getItem("isCreateOpportunity") === "Yes" || sessionStorage.getItem("isRenewOpportunity") === "Yes")
    ) {

      if (
        sessionStorage.getItem("country") === "CA" &&
        sessionStorage.getItem("province") === "Quebec"
      ) {
        const opportunityResult = await axios
          .post(`${process.env.REACT_APP_API_URL}admin/v1/opportunity/create-quebec`, {
            country: sessionStorage.getItem("country"),
            contact_id: sessionStorage.getItem("id"),
            stage: sessionStorage.getItem("stage"),
            performance_manager: {
              name: sessionStorage.getItem("pm_name"),
              id: sessionStorage.getItem("pm_id"),
            },
            product_plan: {
              id: sessionStorage.getItem("plan_id"),
              name: sessionStorage.getItem("plan_name"),
            },
            program_start: sessionStorage.getItem("program_start_date"),
            program_end: sessionStorage.getItem("program_end_date"),
            terminal: sessionStorage.getItem("terminal"),
            currency: "CAD",
            province: sessionStorage.getItem("province"),
            pharmacy: sessionStorage.getItem("plan_type") === "Medication" ? {
              id: sessionStorage.getItem("pharmacy_id"),
              name: sessionStorage.getItem("pharmacy_name"),
              price: sessionStorage.getItem("plan_price"),
              medicine_count: sessionStorage.getItem("pharmacy_medicine_count"),
            } : null,
            is_renewal: sessionStorage.getItem("isRenewOpportunity") === "Yes" ? true : false,
          })
          .then(async (res) => {
            if (res.status === 200) {
              sessionStorage.setItem("opportunity_id", res.data.data.opportunity_id)
              return true
            }
          })
          .catch(async (err) => {
            await sessionStorage.setItem(
              "error",
              "Error in creating opportunity: " +
              err.response.data.message || err.response.data.data
            )
            history.push("/payment-error")
            return false
          })

        if (!opportunityResult) {
          return
        }
      }

      else {
        const opportunityResult = await axios
          .post(`${process.env.REACT_APP_API_URL}admin/v1/opportunity/create`, {
            country: sessionStorage.getItem("country"),
            contact_id: sessionStorage.getItem("id"),
            stage: sessionStorage.getItem("stage"),
            performance_manager: {
              name: sessionStorage.getItem("pm_name"),
              id: sessionStorage.getItem("pm_id"),
            },
            product_plan: sessionStorage.getItem("isPlanAddPercent") === "Yes" 
              ? {
                id: sessionStorage.getItem("plan_id"),
                name: sessionStorage.getItem("plan_name"),
                add_percent: sessionStorage.getItem("plan_add_percent"),
              }
              : {
                id: sessionStorage.getItem("plan_id"),
                name: sessionStorage.getItem("plan_name"),
              },
            program_start: sessionStorage.getItem("program_start_date"),
            program_end: sessionStorage.getItem("program_end_date"),
            terminal: sessionStorage.getItem("terminal"),
            is_renewal: sessionStorage.getItem("isRenewOpportunity") === "Yes" ? true : false,
            ...(sessionStorage.getItem("country") === "UK" && { currency: sessionStorage.getItem("currency") }),
            ...(sessionStorage.getItem("country") === "CA" && { province: sessionStorage.getItem("province") }),
          })
          .then(async (res) => {
            if (res.status === 200) {
              sessionStorage.setItem("opportunity_id", res.data.data.opportunity_id)
              return true
            }
          })
          .catch(async (err) => {
            await sessionStorage.setItem(
              "error",
              "Error in creating opportunity: " +
              err.response.data.message || err.response.data.data
            )
            history.push("/payment-error")
            return false
          })
        
        if (!opportunityResult) {
          return
        }
      }
    }

    if (
      sessionStorage.getItem("createOpportunitySkipped") === "Yes" &&
      sessionStorage.getItem("isUpgradeOpportunity") === "Yes"
    ) {
      const opportunityResult = await axios
        .post(`${process.env.REACT_APP_API_URL}admin/v1/opportunity/upgrade`, {
          country: sessionStorage.getItem("country"),
          contact_id: sessionStorage.getItem("contact_id"),
          opportunity_id: sessionStorage.getItem("opportunity_id"),
          stage: sessionStorage.getItem("stage"),
          product_plan: sessionStorage.getItem("isPlanAddPercent") === "Yes" 
            ? {
              id: sessionStorage.getItem("plan_id"),
              name: sessionStorage.getItem("plan_name"),
              add_percent: sessionStorage.getItem("plan_add_percent"),
            }
            : {
              id: sessionStorage.getItem("plan_id"),
              name: sessionStorage.getItem("plan_name"),
            },
          program_start: sessionStorage.getItem("program_start_date"),
          program_end: sessionStorage.getItem("program_end_date"),
          ...(sessionStorage.getItem("country") === "UK" && { currency: sessionStorage.getItem("currency") }),
          ...(sessionStorage.getItem("country") === "CA" && { province: sessionStorage.getItem("province") }),
        })
        .then(async (res) => {
          if (res.status === 200) {
            sessionStorage.setItem("opportunity_id", res.data.data.opportunity_id)
            return true
          }
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            "Error in upgrading opportunity: " +
            err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
          return false
        })

      if (!opportunityResult) {
        return
      }
    }

    let potentialErrorInfo = ""
    if (sessionStorage.getItem("isCreateOpportunity") === "Yes") {
      if (sessionStorage.getItem("isCreateOpportunity") === "Yes") {
        potentialErrorInfo = "Opportunity created, error in payment collection: "
      } 
      if (sessionStorage.getItem("isUpgradeOpportunity") === "Yes") {
        potentialErrorInfo = "Opportunity upgraded, error in payment collection: "
      }
    }

    if (sessionStorage.getItem("country") == "PCA") {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/collect-payment-pca`,
          {
            country: sessionStorage.getItem("country"),
            amount: Number(amount),
            note: sessionStorage.getItem("note"),
            email: sessionStorage.getItem("email"),
            module: sessionStorage.getItem("module"),
            opportunity_id: sessionStorage.getItem("opportunity_id"),
            contact_id: sessionStorage.getItem("id"),
            transaction_type: sessionStorage.getItem("transaction_type"),
            is_payment_link: true,
            is_discount: sessionStorage.getItem("isDiscount") === "Yes" ? true : false,
          }
        )
        .then(async () => {
          history.push("/payment-success")
          return
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
        })
    }
    else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/collect-payment`,
          {
            country: sessionStorage.getItem("country"),
            amount: Number(amount),
            note: sessionStorage.getItem("note"),
            email: sessionStorage.getItem("email"),
            module: sessionStorage.getItem("module"),
            opportunity_id: sessionStorage.getItem("opportunity_id"),
            contact_id: sessionStorage.getItem("id"),
            transaction_type: sessionStorage.getItem("transaction_type"),
            is_payment_link: true,
            is_discount: sessionStorage.getItem("isDiscount") === "Yes" ? true : false,
          }
        )
        .then(async (res) => {

          console.log(res.data?.data)

          if (!res.data?.data.payment_link) {
            await sessionStorage.setItem(
              "error",
              "Payment link not found, please contact support"
            )
            history.push("/payment-error")
            return
          }

          sessionStorage.setItem("paymentLink", res.data.data.payment_link)
          sessionStorage.setItem("amount", amount)
          history.push("/payment-success")
          return
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            potentialErrorInfo + err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
        })
    }

    setIsProcessing(false)
  }

  const startDate = sessionStorage.getItem("program_start_date") || ""
  const [year0, month0, day0]: string[] = startDate.split("-")
  const formattedStartDate = [day0, month0, year0].join("/")

  const endDate = sessionStorage.getItem("program_end_date") || ""
  const [year1, month1, day1]: string[] = endDate.split("-")
  const formattedEndDate = [day1, month1, year1].join("/")

  return (
    <Transition.Root show={paymentLinkConfirmationModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setPaymentLinkConfirmationModalOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:min-w-642 sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-xl leading-6 font-medium text-gray-900"
                  >
                    Confirm Payment Link Details
                    {sessionStorage.getItem("createOpportunitySkipped") === "Yes" && sessionStorage.getItem("isCreateOpportunity") === "Yes" && "and Create Opportunity"}
                    {sessionStorage.getItem("createOpportunitySkipped") === "Yes" && sessionStorage.getItem("isUpgradeOpportunity") === "Yes" && "and Upgrade Opportunity"}
                    {sessionStorage.getItem("createOpportunitySkipped") === "Yes" && sessionStorage.getItem("isRenewOpportunity") === "Yes" && "and Renew Opportunity"}
                  </Dialog.Title>
                </div>
              </div>

              <div className="w-full sm:w-full mt-7 mb-2">
                <dl>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Payment Amount
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("currency") === "EUR"
                        ? "€"
                        : sessionStorage.getItem("currency") === "GBP"
                        ? "£"
                        : "$"}
                      {amount && parseFloat(amount).toFixed(2)}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Customer Name
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("full_name")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Lead ID
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("id")}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Email
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("email")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Mobile
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("mobile") &&
                      sessionStorage.getItem("mobile") !== "null"
                        ? sessionStorage.getItem("mobile")
                        : "N/A"}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Transaction Type
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("transaction_type")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Description
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("note")}
                    </dd>
                  </div>
                </dl>
              </div>

              {sessionStorage.getItem("module") === "LEADS" && (
                <div className="w-full sm:w-full mt-12 mb-2">
                  <dl>
                    <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Performance Manager
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {sessionStorage.getItem("pm_name")}
                      </dd>
                    </div>
                    <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Product Plan
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {sessionStorage.getItem("plan_name")}
                      </dd>
                    </div>
                    <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Program Start
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {formattedStartDate}
                      </dd>
                    </div>
                    <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Program End
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {formattedEndDate}
                      </dd>
                    </div>
                  </dl>
                </div>
              )}

              <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                  <div className="mt-5 sm:mt-6 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <div className="relative">
                      {isProcessing && (
                        <img
                          width={40}
                          className="inline absolute left-4"
                          src={spinnerBlack}
                          alt="Loading..."
                        />
                      )}
                      <button
                        type="button"
                        className={`${
                          isProcessing
                            ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto"
                            : "bg-FF5733 hover:bg-FF5733_hover text-white"
                        } inline-flex justify-center w-200 rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:text-sm`}
                        onClick={() => {
                          if (!isProcessing) {
                            handlePayment()
                          }
                        }}
                      >
                        {isProcessing ? "Processing..." : "Proceed"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PaymentLinkConfirmationModal
