import { FC, Fragment, useState } from "react"
import { useHistory } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import spinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"
import { Stripe } from "@stripe/stripe-js"

interface UKLeadConfirmationModalProps {
  ukLeadConfirmationModalOpen: boolean
  setUKLeadConfirmationModalOpen: (args: boolean) => void
  cardName: string
  cardNumber: string
  cardMonth: string
  cardYear: string
  cardCVC: string
  cardAmount: string
  stripe: Stripe | null | undefined
}

const UKLeadConfirmationModal: FC<UKLeadConfirmationModalProps> = ({
  ukLeadConfirmationModalOpen,
  setUKLeadConfirmationModalOpen,
  cardName,
  cardNumber,
  cardMonth,
  cardYear,
  cardCVC,
  cardAmount,
  stripe,
}) => {
  const history = useHistory()
  const [isProcessing, setIsProcessing] = useState(false)
  const [confirmDisabled, setConfirmDisabled] = useState(false)
  const [processingPayment, setProcessingPayment] = useState(false)
  const [convertingLead, setConvertingLead] = useState(false)
  const [authenticatingUser, setAuthenticatingUser] = useState(false)

  const handleCancel = () => {
    setUKLeadConfirmationModalOpen(false)
    setIsProcessing(false)
  }

  const handlePayment = async () => {
    setIsProcessing(true)
    setConfirmDisabled(true)
    setProcessingPayment(true)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/create-first-payment`,
        {
          country: sessionStorage.getItem("country"),
          amount: Number(sessionStorage.getItem("amount")),
          note: sessionStorage.getItem("note"),
          email: sessionStorage.getItem("email"),
          card_name: cardName,
          card_number: cardNumber,
          card_month: cardMonth,
          card_year: cardYear,
          card_cvc: cardCVC,
          module: sessionStorage.getItem("module"),
          id: sessionStorage.getItem("id"),
          transaction_type: sessionStorage.getItem("transaction_type"),
          currency: sessionStorage.getItem("currency"),
        }
      )
      .then(async (res) => {
        // 3D SECURE AUTHENTICATION
        if (res.status === 200 && res.data.data.need_confirmation) {
          setProcessingPayment(false)
          setAuthenticatingUser(true)

          if (stripe) {
            stripe
              .confirmCardPayment(res.data.data.client_secret)
              .then(async (res) => {
                if (res.error) {
                  await sessionStorage.setItem("error", res.error.message as string)
                  history.push("/payment-error")
                  return
                }

                setAuthenticatingUser(false)
                setProcessingPayment(true)

                await axios
                  .post(
                    `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/create-first-payment`,
                    {
                      country: sessionStorage.getItem("country"),
                      amount: Number(cardAmount),
                      note: sessionStorage.getItem("note"),
                      email: sessionStorage.getItem("email"),
                      card_name: cardName,
                      card_number: cardNumber,
                      card_month: cardMonth,
                      card_year: cardYear,
                      card_cvc: cardCVC,
                      module: sessionStorage.getItem("module"),
                      id: sessionStorage.getItem("id"),
                      transaction_type:
                        sessionStorage.getItem("transaction_type"),
                      currency: sessionStorage.getItem("currency"),
                      stripe_confirmation: {
                        id: res.paymentIntent.id,
                        amount: res.paymentIntent.amount,
                        capture_method: res.paymentIntent.capture_method,
                        created: res.paymentIntent.created,
                        currency: res.paymentIntent.currency,
                        description: res.paymentIntent.description,
                        payment_method: res.paymentIntent.payment_method,
                        receipt_email: res.paymentIntent.receipt_email,
                        status: res.paymentIntent.status,
                      },
                    }
                  )
                  .then(async () => {
                    setProcessingPayment(false)
                    setConvertingLead(true)

                    await axios
                      .post(
                        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/convert-lead`,
                        {
                          country: sessionStorage.getItem("country"),
                          lead_id: sessionStorage.getItem("id"),
                          stage: `${
                            Number(cardAmount) ===
                            Number(sessionStorage.getItem("plan_price"))
                              ? "Fully Paid"
                              : "Deposit Paid"
                          }`,
                          amount_paid: Number(cardAmount),
                          performance_manager: {
                            id: sessionStorage.getItem("pm_id"),
                            name: sessionStorage.getItem("pm_name"),
                          },
                          product_plan: sessionStorage.getItem("isPlanAddPercent") === "Yes" 
                            ? {
                              id: sessionStorage.getItem("plan_id"),
                              name: sessionStorage.getItem("plan_name"),
                              type: sessionStorage.getItem("plan_type"),
                              add_percent: sessionStorage.getItem("plan_add_percent"),
                            }
                            : {
                              id: sessionStorage.getItem("plan_id"),
                              name: sessionStorage.getItem("plan_name"),
                              type: sessionStorage.getItem("plan_type"),
                            },
                          program_start:
                            sessionStorage.getItem("program_start_date"),
                          program_end:
                            sessionStorage.getItem("program_end_date"),
                          terminal: "Stripe",
                        }
                      )
                      .then(async (res) => {
                        await sessionStorage.setItem(
                          "lead_conversion_status",
                          "SUCCESS"
                        )
                        await sessionStorage.setItem(
                          "contact_id",
                          res.data.data.contact_id
                        )
                        await sessionStorage.setItem(
                          "opportunity_id",
                          res.data.data.opportunity_id
                        )
                        history.push("/payment-success")
                      })
                      .catch(async (err) => {
                        await sessionStorage.setItem(
                          "lead_conversion_status",
                          "ERROR"
                        )
                        await sessionStorage.setItem(
                          "lead_conversion_error",
                          err.response.data.message || err.response.data.data
                        )
                        history.push("/payment-success")
                      })
                  })
              })
          }

          return
        }

        // UK LEAD CONVERSION
        if (res.status === 200) {
          setProcessingPayment(false)
          setConvertingLead(true)

          await axios
            .post(
              `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/convert-lead`,
              {
                country: sessionStorage.getItem("country"),
                lead_id: sessionStorage.getItem("id"),
                stage: `${
                  Number(cardAmount) ===
                  Number(sessionStorage.getItem("plan_price"))
                    ? "Fully Paid"
                    : "Deposit Paid"
                }`,
                amount_paid: Number(cardAmount),
                performance_manager: {
                  id: sessionStorage.getItem("pm_id"),
                  name: sessionStorage.getItem("pm_name"),
                },
                product_plan: sessionStorage.getItem("isPlanAddPercent") === "Yes" 
                  ? {
                    id: sessionStorage.getItem("plan_id"),
                    name: sessionStorage.getItem("plan_name"),
                    type: sessionStorage.getItem("plan_type"),
                    add_percent: sessionStorage.getItem("plan_add_percent"),
                  }
                  : {
                    id: sessionStorage.getItem("plan_id"),
                    name: sessionStorage.getItem("plan_name"),
                    type: sessionStorage.getItem("plan_type"),
                  },
                program_start: sessionStorage.getItem("program_start_date"),
                program_end: sessionStorage.getItem("program_end_date"),
                terminal: `${
                  res.data.data.paymentOption_CBA ? "CBA" : "Stripe"
                }`,
              }
            )
            .then(async (res) => {
              await sessionStorage.setItem("lead_conversion_status", "SUCCESS")
              await sessionStorage.setItem(
                "contact_id",
                res.data.data.contact_id
              )
              await sessionStorage.setItem(
                "opportunity_id",
                res.data.data.opportunity_id
              )
              history.push("/payment-success")
            })
            .catch(async (err) => {
              await sessionStorage.setItem("lead_conversion_status", "ERROR")
              await sessionStorage.setItem(
                "lead_conversion_error",
                err.response.data.message || err.response.data.data
              )
              history.push("/payment-success")
            })
        }

        history.push("/payment-success")
      })
      .catch(async (err) => {
        await sessionStorage.setItem(
          "error",
          err?.response?.data?.message || err?.response?.data?.data || err
        )
        history.push("/payment-error")
      })
  }

  const startDate = sessionStorage.getItem("program_start_date") || ""
  const [year0, month0, day0]: string[] = startDate.split("-")
  const formattedStartDate = [day0, month0, year0].join("/")

  const endDate = sessionStorage.getItem("program_end_date") || ""
  const [year1, month1, day1]: string[] = endDate.split("-")
  const formattedEndDate = [day1, month1, year1].join("/")

  return (
    <Transition.Root show={ukLeadConfirmationModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setUKLeadConfirmationModalOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:min-w-642 sm:p-6 w-800">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-xl leading-6 font-medium text-gray-900"
                  >
                    Confirm Payment Details
                  </Dialog.Title>
                </div>
              </div>

              <div className="w-full sm:w-full mt-7 mb-2">
                <dl>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Payment Amount
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("currency") === "EUR"
                        ? "€"
                        : sessionStorage.getItem("currency") === "GBP"
                        ? "£"
                        : "$"}
                      {Number(sessionStorage.getItem("amount")).toFixed(2)}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Card Number
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("card_number")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Cardholder Name
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {cardName}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Expiration Date
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {cardMonth} / {cardYear}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Card CVV
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {cardCVC}
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="w-full sm:w-full mt-12 mb-2">
                <dl>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Customer Name
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("full_name")}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Lead ID
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("id")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Email
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("email")}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Mobile
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("mobile") &&
                      sessionStorage.getItem("mobile") !== "null"
                        ? sessionStorage.getItem("mobile")
                        : "N/A"}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Transaction Type
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("transaction_type")}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Description
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("note")}
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="w-full sm:w-full mt-12 mb-2">
                <dl>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Performance Manager
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("pm_name")}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Product Plan
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("plan_name")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Program Start
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {formattedStartDate}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Program End
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {formattedEndDate}
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                  <div className="mt-5 sm:mt-6 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <div className="relative">
                      {isProcessing && (
                        <img
                          width={40}
                          className="inline absolute left-0"
                          src={spinnerBlack}
                          alt="Loading..."
                        />
                      )}
                      <button
                        type="button"
                        className={`${
                          isProcessing
                            ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto w-227"
                            : "bg-FF5733 hover:bg-FF5733_hover text-white w-200"
                        } inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:text-sm`}
                        onClick={() => {
                          if (!confirmDisabled) {
                            handlePayment()
                          }
                        }}
                      >
                        {processingPayment
                          ? "Processing payment..."
                          : convertingLead
                          ? "Converting lead..."
                          : authenticatingUser
                          ? "Authenticating user..."
                          : "Confirm Payment"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UKLeadConfirmationModal
