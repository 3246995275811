import { FC, Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"

interface PropTypes {
  open: boolean
  setOpen: (args: boolean) => void
  setCancelSubscriptionModalOpen: (args: boolean) => void
  opportunity_id: string
  recurring_start_date_unix?: number
  recurring_end_date_unix?: number
  recurring_amount?: number
  recurring_frequency?: string
  scheduled_payment?: string
}

const SubscriptionModal: FC<PropTypes> = ({
  open,
  setOpen,
  setCancelSubscriptionModalOpen,
  opportunity_id,
  recurring_start_date_unix,
  recurring_end_date_unix,
  recurring_amount,
  recurring_frequency,
  scheduled_payment,
}) => {

  const handleSubscriptionCancel = () => {
    sessionStorage.setItem("cancel_sub_opportunity_id", opportunity_id)
    setCancelSubscriptionModalOpen(true)
    setOpen(false)
  }

  const formatDate = (unixTime: number) => {
    const date = new Date(unixTime * 1000);
    return date.toLocaleDateString();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start w-full">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                {(scheduled_payment) &&
                    <>
                      <div className="my-4 flex flex-col gap-1">
                        <p className="text-lg font-semibold text-gray-700">
                          Scheduled Payment Information
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Scheduled Payment:</span>{" "}
                          {scheduled_payment}
                        </p>
                      </div>
                    </>
                  }
                  {(recurring_start_date_unix && recurring_end_date_unix && recurring_amount && recurring_frequency) &&
                    <>
                      <div className="my-4 flex flex-col gap-1">
                        <p className="text-lg font-semibold text-gray-700">
                          Recurring Payment Information
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Start Date:</span>{" "}
                          {recurring_start_date_unix ? formatDate(recurring_start_date_unix) : "N/A"}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">End Date:</span>{" "}
                          {recurring_end_date_unix ? formatDate(recurring_end_date_unix) : "N/A"}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Amount:</span>{" "}
                          {recurring_amount ? `$${recurring_amount}` : "N/A"}
                        </p>
                        <p className="text-sm text-gray-500">
                          <span className="font-bold">Frequency:</span>{" "}
                          {recurring_frequency ? recurring_frequency : "N/A"}
                        </p>
                        <div className="flex justify-center">
                          <button className="text-sm font-semibold text-white bg-red-600 hover:bg-red-700 text-center py-1 rounded-md w-200 mt-1"
                            onClick={() => handleSubscriptionCancel()}
                          >
                            Cancel Subscription
                          </button>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SubscriptionModal
