const InfoBlock = () => {
  const dateOfBirth = sessionStorage.getItem("date_of_birth") || ""
  const [year, month, day]: string[] = dateOfBirth.split("-")
  const formattedDateOfBirth = [day, month, year].join("/")

  return (
    <>
      {sessionStorage.getItem("country") && (
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 mt-12 rounded-sm">
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("country") &&
                `Region: ${sessionStorage.getItem("country")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("province") &&
                `Province: ${sessionStorage.getItem(
                  "province"
                )} - ${sessionStorage.getItem("tax_rate")}%`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("module") === "LEADS"
                ? `Status: Lead`
                : sessionStorage.getItem("module") === "CONTACTS"
                  ? `Status: Contact`
                  : null}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("full_name") &&
                `Customer: ${sessionStorage.getItem("full_name")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("email") &&
                `Email: ${sessionStorage.getItem("email")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("mobile") &&
                `Mobile: ${sessionStorage.getItem("mobile") !== "null"
                  ? sessionStorage.getItem("mobile")
                  : "N/A"
                }`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("date_of_birth") &&
                (sessionStorage.getItem("date_of_birth") !== "N/A"
                  ? `Date of Birth: ${formattedDateOfBirth}`
                  : "Date of Birth: N/A")}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("address") &&
                `Address: ${sessionStorage.getItem("address") !== "undefined"
                  ? sessionStorage.getItem("address")
                  : "N/A"
                }`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("opportunity_id") &&
                `Opportunity ID: ${sessionStorage.getItem("opportunity_id")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("plan_name") &&
                `Selected Plan: ${sessionStorage.getItem("plan_name")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("pharmacy_name") &&
                `Selected Pharmacy: ${sessionStorage.getItem("pharmacy_name")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("pharmacy_medicine_count") &&
                `Pharmacy Medicine Count: ${sessionStorage.getItem("pharmacy_medicine_count")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("total_price") &&
                `Total Price: ${sessionStorage.getItem("currency") === "EUR"
                  ? "€"
                  : sessionStorage.getItem("currency") === "GBP"
                    ? "£"
                    : "$"
                }${Number(sessionStorage.getItem("total_price")).toFixed(2)}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("amount_paid") &&
                `Amount Paid: ${sessionStorage.getItem("currency") === "EUR"
                  ? "€"
                  : sessionStorage.getItem("currency") === "GBP"
                    ? "£"
                    : "$"
                }${Number(sessionStorage.getItem("amount_paid")).toFixed(2)}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("amount_remaining") && sessionStorage.getItem("module") !== "LEADS" 
                && sessionStorage.getItem("province") !== "Quebec" && sessionStorage.getItem("isCreateOpportunity") !== "Yes" &&
                `Amount Remaining: ${sessionStorage.getItem("currency") === "EUR"
                  ? "€"
                  : sessionStorage.getItem("currency") === "GBP"
                    ? "£"
                    : "$"
                }${Number(sessionStorage.getItem("amount_remaining")).toFixed(
                  2
                )}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("currency") &&
                sessionStorage.getItem("country") === "UK" &&
                `Currency: ${sessionStorage.getItem("currency") === "EUR"
                  ? "EUR (€)"
                  : "GBP (£)"
                }`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("transaction_type") &&
                `Transaction Type: ${sessionStorage.getItem(
                  "transaction_type"
                )}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("stage") &&
                `Stage: ${sessionStorage.getItem("stage")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("terminal") &&
                `Terminal: ${sessionStorage.getItem("terminal")}`}
            </div>
          </div>
          <div className="text-navy-theme text-sm font-bold leading-7">
            <div className="ml-8">
              {sessionStorage.getItem("note") &&
                `Payment Description: ${sessionStorage.getItem("note")}`}
            </div>
          </div>
          
        </div>
      )}
    </>
  )
}

export default InfoBlock
