import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import PerformanceManagerSearchMenu from "./components/PerformanceManagerSearchMenu"
import ProductPlanSearchMenu from "./components/ProductPlanSearchMenu"
import HelpVideo from "../../components/help-video/HelpVideo"
import "react-datepicker/dist/react-datepicker.css"
import ProductTypeSelectMenu from "./components/ProductTypeSelectMenu"
import PharmacySelectMenu from "./components/PharmacySelectMenu"
import InfoBlock from "../../components/layout/info-block/InfoBlock"

const CreateOpportunityQuebec = () => {
  const region = sessionStorage.getItem("country") as string
  // const contactID = sessionStorage.getItem("id") as string
  // const contactName = sessionStorage.getItem("full_name") as string

  const [, setPmName] = useState<string>("")
  const [pmID, setPmID] = useState<string>("")
  const [productPlanID, setProductPlanID] = useState<string>("")
  const [, setProductPlanName] = useState<string>("")
  const currency = "CAD"

  const [productType, setProductType] = useState<string>("")
  const [pharmacyID, setPharmacyID] = useState<string>("")
  const [, setPharmacyName] = useState<string>("")
  const [, setMedicineTotalCost] = useState<number>(0)
  const [medicineCount, setMedicineCount] = useState<number>(1)
  const [helpVideoOpen, setHelpVideoOpen] = useState<boolean>(false)

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const history = useHistory()

  const handleProceed = () => {
    history.push("/payment-portal/create-opportunity/quebec-details")
  }

  const handleMedicineCount = (count: number) => {
    count = count === 0 ? 1 : count

    let capsulesBy30Count = 0
    let capsulesBy1Count = count

    if (sessionStorage.getItem("pharmacy_30_capsule_cost")) {
      capsulesBy30Count = Math.floor(count / 30)
      capsulesBy1Count = count - capsulesBy30Count * 30
    }

    const totalCost = (
      capsulesBy30Count * Number(sessionStorage.getItem("pharmacy_30_capsule_cost")) 
      + capsulesBy1Count * Number(sessionStorage.getItem("pharmacy_capsule_cost")) 
      + Number(sessionStorage.getItem("pharmacy_ground_delivery_fee")) 
      + Number(sessionStorage.getItem("pharmacy_dispensing_fee"))
    ).toFixed(2)

    sessionStorage.setItem("plan_price", totalCost.toString())
    sessionStorage.setItem("pharmacy_medicine_count", String(count))

    setMedicineCount(count)
    setMedicineTotalCost(+totalCost) // helps in re-renders of cost
    
  }

  useEffect(() => {
    setProductPlanID("")
    setProductPlanName("")
    setPharmacyID("")
    setPharmacyName("")

    sessionStorage.removeItem("plan_id")
    sessionStorage.removeItem("plan_name")
    sessionStorage.removeItem("plan_type")
    sessionStorage.removeItem("plan_price")

  }, [productType])

  useEffect(() => {
    if (!pmID || !productPlanID) {
      setNextButtonDisabled(true)
      return
    }

    if (
      productType === "Medication" && !pharmacyID
    ) {
      setNextButtonDisabled(true)
      return
    }

    setNextButtonDisabled(false)

  }, [
    pmID,
    productPlanID,
    productType,
    pharmacyID
  ])

  useEffect(() => {
    if (pharmacyID) {
      handleMedicineCount(medicineCount)
    }
  }, [pharmacyID])

  useEffect(() => {
    const removeKeys = [
      "opportunity_id",
      "stage",
      "isDiscount",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  return (
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h3 className="text-3xl leading-6 font-medium text-gray-900">
        Create Opportunity
      </h3>
      <button
        className="absolute top-0 right-4 bg-3FB1FC rounded-xl text-white font-semibold p-3 hover:bg-3FB1FC_hover transition-all duration-200 hover:scale-[1.03]"
        onClick={() => setHelpVideoOpen(true)}
      >
        Need Help?
      </button>
      <HelpVideo open={helpVideoOpen} setOpen={setHelpVideoOpen} />

      <div className="flex gap-x-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">
            <div className="w-full px-4 sm:px-6 lg:px-8 my-6">
              <PerformanceManagerSearchMenu
                setPmName={setPmName}
                setPmID={setPmID}
                region={region}
              />
            </div>

            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label
                htmlFor="currency"
                className="block text-sm font-medium text-gray-700"
              >
                Are you charging for a Product Plan or Medication?
              </label>
              <ProductTypeSelectMenu setProductType={setProductType} />
            </div>

            {(productType && pmID) && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <ProductPlanSearchMenu
                  setProductPlanID={setProductPlanID}
                  setProductPlanName={setProductPlanName}
                  region={region}
                  currency={currency}
                  key={productType}
                />
              </div>
            )}

            {(productType === "Medication" && productPlanID && pmID) && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <PharmacySelectMenu
                  setPharmacyID={setPharmacyID}
                  setPharmacyName={setPharmacyName}
                  productPlanID={productPlanID}
                  region={region}
                  key={productPlanID}
                />
              </div>
            )}
            {(productType === "Medication" && pharmacyID && productPlanID && pmID) && (
              <div className="mb-8 sm:mt-0 w-full px-4 sm:px-6 lg:px-8">
                <div className="block text-sm font-medium text-gray-700">
                  Medicine count
                </div>
                <input
                  type="number"
                  className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                  value={Number(medicineCount).toString()}
                  onChange={(e) => handleMedicineCount(+e.target.value)}
                  onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                    (e.target as HTMLInputElement).blur()
                  }
                />
              </div>
            )}
          </div>

          <div className="flex justify-between w-full px-4 sm:px-6 lg:px-8">
            <button
              type="button"
              className={`text-white bg-FF5733 hover:bg-FF5733_hover my-6 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
              onClick={() => history.goBack()}
            >
              Back
            </button>
            <button
              type="button"
              className={`${
                nextButtonDisabled
                  ? "bg-E0E0E0 text-828282"
                  : "text-white bg-FF5733 hover:bg-FF5733_hover"
              } my-6 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
              onClick={() => handleProceed()}
              disabled={nextButtonDisabled}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
      <InfoBlock />
    </div>
  )
}

export default CreateOpportunityQuebec
