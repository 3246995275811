import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"
import DatePicker from "react-datepicker"
import ProductPlanSearchMenu from "../../components/product-plan-search-menu/ProductPlanSearchMenu"
import ProgramDurationButtons from "../../components/program-duration-buttons/ProgramDurationButtons"
import spinnerNavy from "../../../../assets/common/spinnerNavy.svg"
import spinnerBlack from "../../../../assets/common/spinnerBlack.svg"
import CurrencySelectMenu from "../5-currency-select/components/CurrencySelectMenu"
import ProvinceSelectMenu from "../4-province-select/components/ProvinceSelectMenu"
import StageSelectMenu from "../create-opportunity/components/StageSelectMenu"
import TerminalSelectMenu from "../create-opportunity/components/TerminalSelectMenu"
import PerformanceManagerSearchMenu from "../../components/performance_manager_search_menu/PerformanceManagerSearchMenu"

type Customer = {
  first_name: string
  last_name: string
  email: string
  phone: string
  mobile: string
  lead_id: string
  lead_status: string
  lead_created_time: string
  lead_modified_time: string
  lead_source: string
  date_of_birth: string
  contact_id?: string
}

type Opportunity = {
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
  performance_manager_prefill: {
    name: string
    id: string
  }
}

type ProductPlan = {
  Books_Item_Id: null
  Description: string
  Product_Name: string
  id: string
  Product_Active: boolean
  Unit_Price: number
  Product_Region: string
}

function RenewOpportunity() {
  const [isDisabled, setIsDisabled] = useState(true)
  const [description, setDescription] = useState("")
  const [errMessage, setErrMessage] = useState("")
  const [country, setCountry] = useState("")

  // OPPORTUNITY STATE
  const [fetchingCustomer, setFetchingCustomer] = useState(true)
  const [fetchError, setFetchError] = useState("")
  const [customer, setCustomer] = useState<Customer>()
  const [opportunityLoading, setOpportunityLoading] = useState(true)
  const [opportunities, setOpportunities] = useState<Opportunity[]>([])
  const [opportunitiesError, setOpportunitiesError] = useState("")

  // PRODUCT PLAN STATE
  const product_plan_list = [
    {
      Books_Item_Id: "",
      Description: "",
      Product_Name: "",
      Product_Type: "",
      Unit_Price: 0,
      id: "",
    },
  ]
  const [productPlanList, setProductPlanList] = useState(product_plan_list)
  const [productPlanSelectedItem, setProductPlanSelectedItem] = useState("")
  const [productPlanDropdownOpen, setProductPlanDropdownOpen] =
    useState<boolean>(false)
  const [productPlanSearchLoading, setProductPlanSearchLoading] =
    useState(false)
  const [queryError, setQueryError] = useState(false)
  const [queryErrorMessage, setQueryErrorMessage] = useState("")
  const [isProductPlanSelected, setIsProductPlanSelected] = useState(false)
  const [selectProductPlanError, setSelectProductPlanError] = useState(false)

  // START & END DATE STATE
  const [programStartDate, setProgramStartDate] = useState<Date | null>(
    new Date()
  )
  const [programEndDate, setProgramEndDate] = useState<Date | null>(new Date())
  const [selectedDuration, setSelectedDuration] = useState(0)

  // PERFORMANCE MANAGER
  const performance_manager_list: {
    full_name: string
    id: string
    email: string
  }[] = [
    {
      full_name: "",
      id: "",
      email: "",
    },
  ]

  // TERMINAL
  const [terminal, setTerminal] = useState("")

  const [pmID, setPmID] = useState("")
  const [pmName, setPmName] = useState("")
  const [pmSelectedItem, setPmSelectedItem] = useState<string>("")
  const [pmSelectList, setPmSelectList] = useState(performance_manager_list)

  const history = useHistory()
  const params: { contact_id: string; opportunity_id: string; region: string } =
    useParams()
  const { contact_id, opportunity_id, region } = params

  // CURRENCY STATE
  const [currency, setCurrency] = useState("")
  const currencyList = [
    { id: "GBP", value: "GBP" },
    { id: "EUR", value: "EUR" },
  ]

  // PROVINCE STATE
  const [province, setProvince] = useState("")
  const provinceList = [
    { id: "Alberta", tax_rate: 5 },
    { id: "British Columbia", tax_rate: 5 },
    { id: "Manitoba", tax_rate: 5 },
    { id: "New Brunswick", tax_rate: 15 },
    { id: "Newfoundland and Labrador", tax_rate: 15 },
    { id: "Northwest Territories", tax_rate: 5 },
    { id: "Nova Scotia", tax_rate: 15 },
    { id: "Nunavut", tax_rate: 5 },
    { id: "Ontario", tax_rate: 13 },
    { id: "Quebec", tax_rate: 14.975 },
    { id: "Prince Edward Island", tax_rate: 15 },
    { id: "Saskatchewan", tax_rate: 5 },
    { id: "Yukon", tax_rate: 5 },
  ]

  enum Regions {
    AU = "52657875",
    CA = "760139034",
    NZ = "771947106",
    UK = "20067563176",
    PCA = "803222882",
  }

  const currencyMap = {
    AU: "AUD",
    CA: "CAD",
    NZ: "NZD",
    PCA: "AUD",
  }

  // STAGE STATE
  const [stage, setStage] = useState("")

  useEffect(() => {
    const country = Object.keys(Regions).find(
      (key) => Regions[key as keyof typeof Regions] === region
    )

    sessionStorage.setItem("country", country as string)
    sessionStorage.setItem("module", "CONTACTS")
    sessionStorage.setItem("id", contact_id)
    setCountry(country || "")
    setCurrency(currencyMap[country as keyof typeof currencyMap] || "")
  }, [region])

  const handleProceed = async () => {

    if (sessionStorage.getItem("country") === "CA" && !province) {
      setErrMessage("Please select province")
      return
    }
    if (sessionStorage.getItem("country") === "UK" && !currency) {
      setErrMessage("Please select currency")
      return
    }

    sessionStorage.setItem("transaction_type", "Renewal")
    sessionStorage.setItem(
      "program_start_date",
      (programStartDate as Date)?.toISOString().split("T")[0]
    )
    sessionStorage.setItem(
      "program_end_date",
      (programEndDate as Date)?.toISOString().split("T")[0]
    )
    sessionStorage.setItem("note", description)
    sessionStorage.setItem("email", customer!.email)
    sessionStorage.setItem(
      "full_name",
      customer!.first_name + " " + customer!.last_name
    )
    sessionStorage.setItem("mobile", customer!.phone)
    sessionStorage.setItem("opportunity_id", opportunities[0]?.opportunity_id)

    sessionStorage.setItem("stage", stage)
    sessionStorage.setItem("isRenewOpportunity", "Yes")
    sessionStorage.removeItem("invoice_id")
    sessionStorage.setItem("createOpportunitySkipped", "Yes")
    
    if(sessionStorage.getItem("country") === "CA") {
      sessionStorage.setItem("province", province)
    }
    if(sessionStorage.getItem("country") === "UK") {
      sessionStorage.setItem("currency", currency)
    }

    sessionStorage.setItem("card_name", opportunities[0]?.name_on_card)
    sessionStorage.setItem(
      "last_four_digits",
      opportunities[0]?.last_four_digit
    )
    sessionStorage.setItem("card_brand", opportunities[0]?.brand)

    sessionStorage.setItem("terminal", terminal)
    sessionStorage.setItem("pm_id", pmID)
    sessionStorage.setItem("pm_name", pmName)
    
    history.push(
      `/payment-portal/payment-type`
    )
    return
  }

  const fetchCustomer = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/fetch-customer`,
        {
          country: country,
          module: "CONTACTS",
          id: contact_id,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setFetchingCustomer(false)
        setCustomer(data)
      })
      .catch((err) => {
        setFetchError(err.response.data.message)
        setFetchingCustomer(false)
      })
  }

  const getOpportunity = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/get-opportunity`,
        {
          contact_id: contact_id,
          country: sessionStorage.getItem("country"),
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setOpportunityLoading(false)
        const opportunity = data.filter(
          (item: Opportunity) => item.opportunity_id === opportunity_id
        )

        sessionStorage.setItem("currency", opportunity[0].currency)
        setOpportunities(opportunity)
        setPmID(opportunity[0].performance_manager_prefill.id)
        setPmName(opportunity[0].performance_manager_prefill.full_name)
        setPmSelectedItem(opportunity[0].performance_manager_prefill.full_name)
        setPmSelectList([
          opportunity[0].performance_manager_prefill
        ])
      })
      .catch((err) => {
        setOpportunityLoading(false)
        setOpportunitiesError(err.response.data.message)
      })
  }

  const searchPlans = async () => {
    setProductPlanSearchLoading(true)
    setQueryError(false)
    setQueryErrorMessage("")
    setSelectProductPlanError(false)

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/get-product`,
        {
          country: sessionStorage.getItem("country"),
          searchString: productPlanSelectedItem,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setProductPlanSearchLoading(false)
        if (sessionStorage.getItem("country") === "UK") {
          setProductPlanList(
            data.filter((plan: ProductPlan) =>
              sessionStorage.getItem("currency") === "GBP"
                ? plan.Product_Region === "United Kingdom"
                : sessionStorage.getItem("currency") === "EUR"
                ? plan.Product_Region === "Ireland"
                : plan
            )
          )
        } else {
          setProductPlanList(data)
        }
        setProductPlanDropdownOpen(true)
      })
      .catch((err) => {
        setProductPlanSearchLoading(false)
        setProductPlanDropdownOpen(false)
        setQueryError(true)
        if (typeof err?.response?.data.message === "string") {
          setQueryErrorMessage(err.response.data.message)
          return
        }
      })
  }

  useEffect(() => {
    if (country) {
      fetchCustomer()
    }
  }, [country])

  useEffect(() => {
    if (customer) {
      getOpportunity()
    }
  }, [customer])

  useEffect(() => {

    if (
      !customer ||
      ((sessionStorage.getItem("invoice_id") === "null" || sessionStorage.getItem("invoice_number") === "null") && country !== "PCA") ||
      !isProductPlanSelected ||
      !programStartDate ||
      !programEndDate ||
      !description ||
      !stage ||
      !pmSelectedItem ||
      !terminal
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [
    customer,
    isProductPlanSelected,
    programStartDate,
    programEndDate,
    description,
    stage,
    pmSelectedItem,
    terminal
  ])

  useEffect(() => {
    const removeKeys = [
      "opportunity_id",
      "card_name",
      "last_four_digits",
      "card_brand",
      "plan_name",
      "plan_id",
      "plan_type",
      "plan_price",
      "total_price",
      "amount_paid",
      "amount_remaining",
      "currency",
      "transaction_type",
      "note",
      "email",
      "full_name",
      "mobile",
      "program_start_date",
      "program_end_date",
      "createOpportunitySkipped",
      "createOpportunitySuccess",
      "isCreateOpportunity",
      "isUpgradeOpportunity",
      "isRenewOpportunity",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [invoiceIndex, setInvoiceIndex] = useState(99)
  const [invoiceSuccess, setInvoiceSuccess] = useState(true)
  const [invoiceMessage, setInvoiceMessage] = useState("")

  const createInvoice = async (opportunityId: string, index: number) => {
    setInvoiceMessage("")
    setInvoiceLoading(true)
    setInvoiceIndex(index)
    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/invoice/create`, {
        country: sessionStorage.getItem("country"),
        email: customer!.email,
        opportunity_id: opportunityId,
      })
      .then((res) => {
        setInvoiceMessage(res.data.message)
        setInvoiceSuccess(true)
        getOpportunity()
      })
      .catch((err) => {
        setInvoiceMessage(err.response.data.message)
        setInvoiceSuccess(false)
      })
    setInvoiceLoading(false)
  }

  return (
    <>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h3 className="text-3xl leading-6 font-medium text-gray-900">Renew Opportunity</h3>

        {fetchingCustomer ? (
          <div className="flex mt-2">
            <img width={40} className="inline" src={spinnerNavy} alt="Loading..." />
            <h4 className="mt-2 text-lg font-medium text-navy-theme">Loading customer information...</h4>
          </div>
        ) : fetchError !== "" ? (
          <h4 className="mt-2 text-lg font-medium text-E84545">{fetchError}</h4>
        ) : (
          <>
            <h4 className="mt-2 text-lg font-medium text-gray-500">Name: {customer?.first_name + " " + customer?.last_name}</h4>
            <h4 className="text-lg font-medium text-gray-500">Email: {customer?.email}</h4>
            <h4 className="text-lg font-medium text-gray-500">Contact ID: {contact_id}</h4>
          </>
        )}
      </div>

      <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-8">
        <div className="w-full h-full flex flex-col justify-evenly items-center">
          <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
            {opportunityLoading ? (
              <div className="pt-6">
                <img width={40} className="inline" src={spinnerNavy} alt="Loading..." />
                <span className="font-medium text-navy-theme">Opportunity loading...</span>
              </div>
            ) : fetchError !== "" ? (
              <div className={`font-bold text-E84545`}>{fetchError}</div>
            ) : opportunitiesError !== "" ? (
              <div className={`font-bold text-E84545`}>{opportunitiesError}</div>
            ) : (
              <>
                <h4 className="mt-3 text-lg font-medium">Current plan to be renewed</h4>
                {opportunities.map((opportunity, index) => (
                  <div key={index}>
                    <div className={`border-2 overflow-hidden shadow rounded-lg mt-2 p-4 relative bg-white`}>
                      <div className="ml-4 grid grid-rows-5 grid-cols-2 gap-y-1 grid-flow-col">
                        <p className="text-sm font-semibold text-gray-500 ">
                          Name: <span className="font-normal">{opportunity.opportunity_name}</span>
                        </p>
                        <p className="text-sm font-semibold text-gray-500">
                          Enquiry: <span className="font-normal">{opportunity.enquiry}</span>
                        </p>
                        <p className="text-sm font-semibold text-gray-500">
                          Opp ID: <span className="font-normal">{opportunity.opportunity_id}</span>
                        </p>
                        {country !== "PCA" && (
                          <>
                            <p className="text-sm font-semibold text-gray-500">
                              Invoice ID: <span className="font-normal">{opportunity.invoice_id || "Unavailable"}</span>
                            </p>
                            <p className="text-sm font-semibold text-gray-500">
                              Invoice #: <span className="font-normal">{opportunity.invoice_number || "Unavailable"}</span>
                            </p>
                          </>
                        )}
                        <p className="text-sm font-semibold text-gray-500">
                          Plan Name: <span className="font-normal">{opportunity.plan_info?.name}</span>
                        </p>
                        <p className="text-sm font-semibold text-gray-500">
                          Total price:{" "}
                          <span className="font-normal">
                            {opportunity?.currency === "EUR" ? "€" : opportunity?.currency === "GBP" ? "£" : "$"}
                            {opportunity.total_price.toFixed(2)}
                          </span>
                        </p>
                        <p className="text-sm font-semibold text-gray-500">
                          Amount paid:{" "}
                          <span className="font-normal">
                            {opportunity?.currency === "EUR" ? "€" : opportunity?.currency === "GBP" ? "£" : "$"}
                            {(opportunity.total_price - opportunity.amount_paid).toFixed(2)}
                          </span>
                        </p>
                        {opportunity.amount_paid === 0 ? (
                          <p className="text-sm font-bold text-3fc69d">Paid in full</p>
                        ) : opportunity.amount_paid < 0 ? (
                          <p className="text-sm font-bold text-E84545">
                            Customer overcharged {opportunity?.currency === "EUR" ? "€" : opportunity?.currency === "GBP" ? "£" : "$"}
                            {Number(String(opportunity.amount_paid).substring(1)).toFixed(2)}
                          </p>
                        ) : (
                          <p className="text-sm font-semibold text-gray-500">
                            Amount remaining:{" "}
                            <span className="font-normal">
                              {opportunity?.currency === "EUR" ? "€" : opportunity?.currency === "GBP" ? "£" : "$"}
                              {opportunity.amount_paid.toFixed(2)}
                            </span>
                          </p>
                        )}
                        <div
                          className={`h-full w-4 absolute top-0 left-0 bg-3FB1FC ${opportunity.amount_paid === 0 && "bg-3fc69d"} ${
                            (opportunity.amount_paid < 0 || !opportunity.invoice_id || !opportunity.invoice_number) && country !== "PCA" && "bg-E84545"
                          }`}
                        />
                      </div>
                      {(!opportunity.invoice_id || !opportunity.invoice_number) && country !== "PCA" && (
                        <>
                          <p className="ml-4 mt-4 text-sm font-bold text-E84545">
                            Invoice data is unavailable within the CRM. Please click on the &quot;Create Invoice&quot; button to proceed.
                          </p>
                          {invoiceMessage && index === invoiceIndex ? (
                            <button
                              className={`w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-auto ${
                                invoiceSuccess ? "bg-3fc69d" : "bg-E84545"
                              } text-white rounded-lg h-10`}
                            >
                              {invoiceMessage}
                            </button>
                          ) : (
                            <>
                              {invoiceLoading && index === invoiceIndex && (
                                <img width={40} className="absolute left-[194px] bottom-4 invert border-none" src={spinnerBlack} alt="Loading..." />
                              )}
                              <button
                                onClick={() => createInvoice(opportunity.opportunity_id, index)}
                                className="w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-pointer bg-navy-theme text-white rounded-lg h-10"
                              >
                                {invoiceLoading && index === invoiceIndex ? "Creating invoice..." : "Create Invoice"}
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      {!opportunityLoading && fetchError === "" && opportunitiesError === "" && (
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-8">
          <div className="w-full h-full flex flex-col justify-evenly items-center my-6">
            <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
              <div className="mb-6">
                <PerformanceManagerSearchMenu
                  setPmName={setPmName}
                  setPmID={setPmID}
                  setSelectedItem={setPmSelectedItem}
                  selectList={pmSelectList}
                  setSelectList={setPmSelectList}
                  selectedItem={pmSelectedItem}
                  region={country}
                />
              </div>
              <div className="flex">
                <label htmlFor="product_plan" className="block text-sm font-medium text-gray-700 mr-2">
                  Product Plan
                </label>
                {selectProductPlanError && <span className="text-sm text-red-500 font-bold">Please select product plan before proceeding</span>}
                {productPlanSearchLoading && (
                  <>
                    <img width={24} className="inline" src={spinnerNavy} alt="Loading..." />
                    <span className="font-medium text-sm text-navy-theme">Searching for plans...</span>
                  </>
                )}
                {queryError && (
                  <span className="font-medium text-sm text-E84545 ml-2">
                    {queryErrorMessage !== "" ? queryErrorMessage : "No results found. Please try again with a different query."}
                  </span>
                )}
              </div>
              <ProductPlanSearchMenu
                searchPlans={searchPlans}
                productPlanList={productPlanList}
                productPlanSelectedItem={productPlanSelectedItem}
                setProductPlanSelectedItem={setProductPlanSelectedItem}
                productPlanDropdownOpen={productPlanDropdownOpen}
                setProductPlanDropdownOpen={setProductPlanDropdownOpen}
                setIsProductPlanSelected={setIsProductPlanSelected}
              />
            </div>
            <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
              <label htmlFor="program_start_date" className="block text-sm font-medium text-gray-700">
                Select Start Date of Program
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Select program start"
                selected={programStartDate}
                onChange={(date) => setProgramStartDate(date)}
                showPopperArrow={false}
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={5}
                className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4  items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
              />
            </div>
            <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
              <label htmlFor="program_end_date" className="block text-sm font-medium text-gray-700">
                Select End Date of Program
              </label>
              <ProgramDurationButtons
                programStartDate={programStartDate}
                setProgramEndDate={setProgramEndDate}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
              />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Select program end"
                selected={programEndDate}
                onChange={(date) => {
                  setSelectedDuration(0)
                  setProgramEndDate(date)
                }}
                showPopperArrow={false}
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={5}
                className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4   items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
              />
            </div>
            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                className="mt-2 w-full h-36 bg-E0E0E0 border-c4c4c4 border rounded-md focus:border-c4c4c4 focus:outline-none focus:ring-0 font-medium text-828282 leading-5"
                style={{ resize: "none" }}
                placeholder="Enter details of payment"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {country === "UK" && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                  Currency
                </label>
                <CurrencySelectMenu currencyList={currencyList} currencySelectedItem={currency} setCurrencySelectedItem={setCurrency} />
              </div>
            )}
            {country === "CA" && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
                <label htmlFor="province" className="block text-sm font-medium text-gray-700">
                  Province
                </label>
                <ProvinceSelectMenu provinceList={provinceList} provinceSelectedItem={province} setProvinceSelectedItem={setProvince} />
              </div>
            )}
            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label htmlFor="stage" className="block text-sm font-medium text-gray-700">
                Stage
              </label>
              <StageSelectMenu setStage={setStage} />
            </div>
            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label htmlFor="stage" className="block text-sm font-medium text-gray-700">
                Terminal
              </label>
              <TerminalSelectMenu setTerminal={setTerminal} />
            </div>
          </div>
        </div>
      )}

      <div className="w-full flex flex-col">
        <button
          type="button"
          className={`${
            isDisabled ? "bg-E0E0E0 text-828282" : "text-white bg-FF5733 hover:bg-FF5733_hover"
          } mt-4 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 mx-auto`}
          onClick={() => {
            handleProceed()
          }}
          disabled={isDisabled}
        >
          <span>Proceed</span>
        </button>
        {errMessage && (
          <div className="flex mx-auto justify-center mt-4 bg-red-200 border border-red-500 text-red-700 py-2 px-4 rounded-lg">{errMessage}</div>
        )}
      </div>
    </>
  )
}

export default RenewOpportunity
