import { FC, useState, useEffect, useRef, useCallback } from "react"
import { ReactComponent as Arrow } from "../../../../../assets/common/arrowDown.svg"

interface TerminalSelectMenuProps {
  setTerminal: (args: string) => void
}

const TerminalSelectMenu: FC<TerminalSelectMenuProps> = ({
  setTerminal,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<string>("Select Terminal")
  const dropDownRef = useRef<HTMLDivElement>(null)

  const terminal_list_all: { id: string; value: string }[] = [
    { id: "CBA", value: "CBA" },
    { id: "EFT", value: "EFT" },
    { id: "Stripe", value: "Stripe" },
  ]

  const terminal_list_quebec: { id: string; value: string }[] = [
    { id: "EFT", value: "EFT" },
    { id: "Stripe", value: "Stripe" },
  ]

  const terminal_list: { id: string; value: string }[] =
    (sessionStorage.getItem("province") === "Quebec" && sessionStorage.getItem("isCreateOpportunity") === "Yes")
      ? terminal_list_quebec
      : terminal_list_all

  const openHandler = (): void => {
    setIsOpened(!isOpened)
  }

  const selectHandler = (id: string) => {
    const selected = terminal_list.find((el) => el.id === id)
    setSelectedItem(selected!.id)
    setTerminal(selected!.value)
    setIsOpened(false)
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setIsOpened(false)
      }
    },
    [setIsOpened]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div className="relative mt-2">
      <div
        className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
        onClick={openHandler}
        ref={dropDownRef}
      >
        <div className="flex items-center pl-4 font-medium text-828282 w-full">
          {selectedItem}
        </div>
        <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
          <Arrow />
        </div>
        {isOpened && (
          <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
            {terminal_list.map(({ id }, index) => (
              <div
                className={`${
                  index === 0 ? "" : "border-t border-F0F0F0"
                } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                key={id}
                onClick={() => selectHandler(id)}
              >
                {id}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default TerminalSelectMenu
