import { FC, Fragment, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import {
  CheckIcon,
  CheckCircleIcon,
  XIcon,
  XCircleIcon,
} from "@heroicons/react/outline"
import spinnerBlack from "../../../assets/common/spinnerBlack.svg"
import axios from "axios"
import { useDispatch } from "react-redux"
import { logout } from "../../../redux/auth/actions"

interface PropTypes {
  open: boolean
  setOpen: (args: boolean) => void
  type: string
  data: {
    performance_manager: {
      id: string
      name: string
    }
    product_plan: {
      id: string
      name: string
      type: string
    }
    id: number
    module_name: string
    email: string
    country: string
    payment_type: string
    opportunity_id: string
    lead_id: string
    contact_id: string
    admin_id: string
    url: string
    amount: number
    eft_status: string
    stage: string
    program_start: string
    program_end: string
    province: string
    currency: string
    status: true
    createdById: string
    updatedById: string
    createdAt: string
    updatedAt: string
    admin: {
      id: string
      first_name: string
      last_name: string
    }
    createdBy: {
      first_name: string
      last_name: string
      email: string
    }
    updatedBy: {
      first_name: string
      last_name: string
      email: string
    }
  }
  getEFTPayments: () => void
}

const ApprovalModal: FC<PropTypes> = ({
  open,
  setOpen,
  type,
  data,
  getEFTPayments,
}) => {
  const cancelButtonRef = useRef(null)
  const approve = type === "APPROVE"
  const dispatch = useDispatch()
  const [alertType, setAlertType] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const [buttonsHidden, setButtonsHidden] = useState(false)
  const [approvalComplete, setApprovalComplete] = useState(false)

  const handleAction = async (approval: boolean) => {
    setIsProcessing(true)
    setButtonsHidden(true)

    await axios
      .post(
        `${
          data.country === "PCA"
            ? `${process.env.REACT_APP_API_URL}admin/v1/eft-payment/action-pca`
            : data.province === "Quebec"
            ? `${process.env.REACT_APP_API_URL}admin/v1/eft-payment/action-quebec`
            : `${process.env.REACT_APP_API_URL}admin/v1/eft-payment/action`
        }`,
        {
          id: data.id,
          request_status: `${approval ? "APPROVED" : "REJECTED"}`,
        }
      )
      .then(async (res) => {
        setApprovalComplete(true)
        setAlertType("success")
        setAlertMessage(res.data.message)
        await getEFTPayments()

        setTimeout(() => {
          setOpen(false)
          setApprovalComplete(false)
          setAlertType("")
          setAlertMessage("")
        }, 3000)
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          "Invalid Token. Admin Authentication Token does not match"
        ) {
          sessionStorage.setItem("authError", err.response.data.message)
          dispatch(logout())
        }
        setAlertType("error")
        setAlertMessage(err.response.data.message)
      })

    setButtonsHidden(false)
    setIsProcessing(false)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpen(false)
          setAlertType("")
          setAlertMessage("")
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`${
                      approve ? "bg-green-100" : "bg-red-100"
                    } mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10`}
                  >
                    {approve ? (
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <XIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {approve ? "Approve" : "Reject"} EFT Payment
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to{" "}
                        {approve ? "approve" : "reject"} this payment?
                      </p>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500 font-bold">
                          Email:{" "}
                          <span className="font-normal">{data.email}</span>
                        </p>
                        <p className="text-sm text-gray-500 font-bold">
                          Payment: $
                          <span className="font-normal">
                            {data?.amount?.toFixed(2)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {alertType === "error" && (
                  <div className="my-4 rounded-md bg-red-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <XCircleIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-red-800">
                          {alertMessage}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}

                {alertType === "success" && (
                  <div className="my-4 rounded-md bg-green-50 p-4">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-green-800">
                          {alertMessage}
                        </h3>
                      </div>
                    </div>
                  </div>
                )}

                {!approvalComplete && (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <div className="relative">
                      {isProcessing && (
                        <img
                          width={40}
                          className="inline absolute left-4"
                          src={spinnerBlack}
                          alt="Loading..."
                        />
                      )}
                      <button
                        type="button"
                        className={`${
                          isProcessing
                            ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto pl-10 pr-6"
                            : approve
                            ? "bg-green-600 hover:bg-green-700"
                            : "bg-red-600 hover:bg-red-700"
                        } w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm`}
                        onClick={() => {
                          if (!isProcessing) {
                            handleAction(approve)
                          }
                        }}
                      >
                        {isProcessing
                          ? "Processing"
                          : approve
                          ? "Approve"
                          : "Reject"}
                      </button>
                    </div>
                    {!buttonsHidden && (
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          setOpen(false)
                          setAlertType("")
                          setAlertMessage("")
                        }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ApprovalModal
