import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import HelpVideo from "../../../components/help-video/HelpVideo"
import CustomerInfoModal from "../../../components/customer-info-modal/CustomerInfoModal"
import { ReactComponent as Arrow } from "../../../../../assets/common/arrowDown.svg"
import spinnerNavy from "../../../../../assets/common/spinnerNavy.svg"
import axios from "axios"

type Customer = {
  first_name: string
  last_name: string
  email: string
  phone: string
  date_of_birth: string
  address: string
}

function LeadPayment() {
  const [isDisabled, setIsDisabled] = useState(true)
  const [fetchingCustomer, setFetchingCustomer] = useState(true)
  const [fetchError, setFetchError] = useState("")
  const [customer, setCustomer] = useState<Customer>()
  const [helpVideoOpen, setHelpVideoOpen] = useState<boolean>(false)
  const [customerInfoModalOpen, setCustomerInfoModalOpen] = useState(false)

  const params: { lead_id: string; region: string } = useParams()
  const { lead_id, region } = params
  const dropDownRef = useRef<HTMLDivElement>(null)

  const fetchCustomer = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/fetch-customer`,
        {
          country:
            region === "52657875"
              ? "AU"
              : region === "760139034"
              ? "CA"
              : region === "771947106"
              ? "NZ"
              : region === "20067563176"
              ? "UK"
              : region === "803222882"
              ? "PCA"
              : "",
          module: "LEADS",
          id: lead_id,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res
        setFetchingCustomer(false)
        setCustomer(data)
      })
      .catch((err) => {
        setFetchError(err.response.data.message)
        setFetchingCustomer(false)
      })
  }

  const transaction_list = [{ id: "Sale FE" }]
  const [transactionSelectedItem, setTransactionSelectedItem] = useState(
    "Select transaction type"
  )
  const [isTransactionOpened, setIsTransactionOpened] = useState<boolean>(false)
  const transactionOpenHandler = (): void => {
    setIsTransactionOpened(!isTransactionOpened)
  }
  const [description, setDescription] = useState("")

  const handleProceed = () => {
    sessionStorage.setItem("email", customer?.email as string)
    sessionStorage.setItem(
      "full_name",
      customer?.first_name + " " + customer?.last_name
    )
    sessionStorage.setItem("mobile", customer?.phone as string)
    sessionStorage.setItem("date_of_birth", customer?.date_of_birth as string)
    sessionStorage.setItem("address", customer?.address as string)
    sessionStorage.setItem("note", description)
    switch (sessionStorage.getItem("country")) {
      case "AU":
        sessionStorage.setItem("currency", "AU$")
        break
      case "CA":
        sessionStorage.setItem("currency", "CA$")
        break
      case "NZ":
        sessionStorage.setItem("currency", "NZ$")
        break
      case "PCA":
        sessionStorage.setItem("currency", "AU$")
        break
    }

    setCustomerInfoModalOpen(true)
  }

  const transactionSelectHandler = (id: string) => {
    const selected = transaction_list.find((el) => el.id === id)
    setTransactionSelectedItem(selected?.id as string)
    sessionStorage.setItem("transaction_type", selected?.id || "")
  }

  useEffect(() => {
    if (region === "52657875") {
      sessionStorage.setItem("country", "AU")
    } else if (region === "760139034") {
      sessionStorage.setItem("country", "CA")
    } else if (region === "771947106") {
      sessionStorage.setItem("country", "NZ")
    } else if (region === "20067563176") {
      sessionStorage.setItem("country", "UK")
    } else if (region === "803222882") {
      sessionStorage.setItem("country", "PCA")
    }
    sessionStorage.setItem("module", "LEADS")
    sessionStorage.setItem("id", lead_id)
    fetchCustomer()
  }, [])

  useEffect(() => {
    if (
      !customer ||
      transactionSelectedItem === "Select transaction type" ||
      description === ""
    ) {
      setIsDisabled(true)
    } else if (
      customer &&
      transactionSelectedItem !== "Select transaction type" &&
      description !== ""
    ) {
      setIsDisabled(false)
    }
  }, [customer, transactionSelectedItem, description])

  useEffect(() => {
    const removeKeys = [
      "currency",
      "note",
      "transaction_type",
      "email",
      "full_name",
      "mobile",
      "date_of_birth",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
    setCustomerInfoModalOpen(false)
  }, [])

  return (
    <>
      <CustomerInfoModal
        customerInfoModalOpen={customerInfoModalOpen}
        setCustomerInfoModalOpen={setCustomerInfoModalOpen}
        fromZoho
      />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h3 className="text-3xl leading-6 font-medium text-gray-900">
          Lead Payment
        </h3>
        <button
          className="absolute top-0 right-4 bg-3FB1FC rounded-xl text-white font-semibold p-3 hover:bg-3FB1FC_hover transition-all duration-200 hover:scale-[1.03]"
          onClick={() => setHelpVideoOpen(true)}
        >
          Need Help?
        </button>
        <HelpVideo open={helpVideoOpen} setOpen={setHelpVideoOpen} />

        {fetchingCustomer ? (
          <div className="flex mt-2">
            <img
              width={40}
              className="inline"
              src={spinnerNavy}
              alt="Loading..."
            />
            <h4 className="mt-2 text-lg font-medium text-navy-theme">
              Loading customer information...
            </h4>
          </div>
        ) : fetchError !== "" ? (
          <h4 className="mt-2 text-lg font-medium text-E84545">{fetchError}</h4>
        ) : (
          <>
            <h4 className="mt-2 text-lg font-medium text-gray-500">
              Name: {customer?.first_name + " " + customer?.last_name}
            </h4>
            <h4 className="text-lg font-medium text-gray-500">
              Email: {customer?.email}
            </h4>
            <h4 className="text-lg font-medium text-gray-500">
              Lead ID: {lead_id}
            </h4>
          </>
        )}
      </div>

      <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-8">
        <div className="w-full h-full flex flex-col justify-evenly items-center my-6">
          <div className="w-full px-4 sm:px-6 lg:px-8">
            <label
              htmlFor="transaction_type"
              className="block text-sm font-medium text-gray-700"
            >
              Transaction Type
            </label>
            <div className={`relative mt-2`}>
              <div
                className="bg-E0E0E0 h-12 w-full rounded-md flex cursor-pointer border-c4c4c4 border"
                onClick={transactionOpenHandler}
                ref={dropDownRef}
              >
                <div className="flex items-center pl-4 font-medium text-828282 w-full">
                  {transactionSelectedItem}
                </div>
                <div className="flex items-center pr-4 justify-center min-w-2.375 w-2.375 h-full">
                  <Arrow />
                </div>
                {isTransactionOpened && (
                  <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-10">
                    {transaction_list.map(({ id }, index) => (
                      <div
                        className={`${
                          index === 0 ? "" : "border-t border-F0F0F0"
                        } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                        key={id}
                        onClick={() => transactionSelectHandler(id)}
                      >
                        {id}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full px-4 sm:px-6 lg:px-8 mt-6">
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <textarea
              className="mt-2 w-full h-36 bg-E0E0E0 border-c4c4c4 border rounded-md focus:border-c4c4c4 focus:outline-none focus:ring-0 font-medium text-828282 leading-5"
              style={{ resize: "none" }}
              placeholder="Enter details of payment"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex">
        <button
          type="button"
          className={`${
            isDisabled
              ? "bg-E0E0E0 text-828282"
              : "text-white bg-FF5733 hover:bg-FF5733_hover"
          } my-9 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 mx-auto`}
          onClick={() => {
            handleProceed()
          }}
          disabled={isDisabled}
        >
          Proceed
        </button>
      </div>
    </>
  )
}

export default LeadPayment
