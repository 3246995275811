import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { ReactComponent as Error } from "../../../../../assets/common/error.svg"

function PaymentError() {
  const history = useHistory()
  const [isEFTPayment, setIsEFTPayment] = useState(false)

  useEffect(() => {
    if (sessionStorage.getItem("eft_payment")) {
      setIsEFTPayment(true)
    }
  }, [])

  const regionIDs = {
    AU: "52657875",
    CA: "760139034",
    NZ: "771947106",
    UK: "20067563176",
    PCA: "803222882",
  }

  const regionID = regionIDs[sessionStorage.getItem("country") as keyof typeof regionIDs] || "";

  console.log(`/opportunity-payment/${regionID}/${sessionStorage.getItem("contact_id")}/${sessionStorage.getItem("opportunity_id")}`)

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">
            <div className="text-E84545 text-3xl sm:text-4xl font-semibold mt-8">
              There was an error
            </div>
            <Error className="mt-42px" />
            {isEFTPayment ? (
              <div className="px-9 sm:px-0 text-xl text-828282 max-w-421 mt-9">
                There was an API issue while processing the EFT payment. Please
                try again.
              </div>
            ) : (
              <div className="px-9 sm:px-0 text-xl text-828282 max-w-421 mt-9">
                There was either an API or banking issue while processing the
                payment. Please try another payment method.
              </div>
            )}
            <div className={`px-9 sm:px-0 text-xl max-w-421 mt-9 ${sessionStorage.getItem("client_error") === "Yes" ? "text-green-500" : "text-E84545"}`}>
              {sessionStorage.getItem("error") &&
                sessionStorage.getItem("error")}
            </div>
            {sessionStorage.getItem("createOpportunitySkipped") === "Yes" ? (
              <button
                type="button"
                className={
                  "text-white bg-FF5733 hover:bg-FF5733_hover my-9 w-62 sm:w-64 h-14 text-center px-6 border border-transparent font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
                }
                onClick={() => history.push(
                  `/opportunity-payment/${regionID}/${sessionStorage.getItem("contact_id")}/${sessionStorage.getItem("opportunity_id")}`
                )}
              >
                Take Payment
              </button>
            ) : isEFTPayment ? (
              <button
                type="button"
                className={
                  "text-white bg-FF5733 hover:bg-FF5733_hover my-9 w-62 sm:w-64 h-14 text-center px-6 border border-transparent font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
                }
                onClick={() => history.goBack()}
              >
                Back to EFT Upload
              </button>
            ) : sessionStorage.getItem("error") ===
              "payment_method are invalid please update valid payment method" ? (
              <button
                type="button"
                className={
                  "text-white bg-FF5733 hover:bg-FF5733_hover my-9 w-62 sm:w-64 h-14 text-center px-6 border border-transparent font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
                }
                onClick={() => history.push("/update-payment")}
              >
                Update payment method
              </button>
            ) : (
              <button
                type="button"
                className={
                  "text-white bg-FF5733 hover:bg-FF5733_hover my-9 w-62 sm:w-64 h-14 text-center px-6 border border-transparent font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
                }
                onClick={() => history.goBack()}
              >
                Back to Payment Details
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentError
