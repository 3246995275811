import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import DatePicker from "react-datepicker"
import { addDays } from 'date-fns';
import StageSelectMenu from "./components/StageSelectMenu"
import TerminalSelectMenu from "./components/TerminalSelectMenu"
import HelpVideo from "../../components/help-video/HelpVideo"
import "react-datepicker/dist/react-datepicker.css"
import PaymentFrequencySelectMenu from "./components/PaymentFrequencySelectMenu"
import TransactionTypeSelectMenu from "./components/TransactionTypeSelectMenu";
import InfoBlock from "../../components/layout/info-block/InfoBlock";
import axios from "axios";
import spinnerBlack from "../../../../assets/common/spinnerBlack.svg"

const CreateOpportunityQuebec = () => {
  // const region = sessionStorage.getItem("country") as string
  // const contactID = sessionStorage.getItem("id") as string
  // const contactName = sessionStorage.getItem("full_name") as string

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [stage, setStage] = useState("")
  const [terminal, setTerminal] = useState("")
  const [selectedDuration, setSelectedDuration] = useState<number>(0)

  const [helpVideoOpen, setHelpVideoOpen] = useState<boolean>(false)

  const [initialDeposit, setInitialDeposit] = useState(0)
  const [balanceError, setBalanceError] = useState(false)

  const [isScheduledDeposit, setIsScheduledDeposit] = useState(false)
  const [scheduledDepositAmount, setScheduledDepositAmount] = useState(0)
  const [scheduledDepositDate, setScheduledDepositDate] = useState(addDays(new Date(), 1))

  const [isRecurring, setIsRecurring] = useState(false)
  const [recurringAmount, setRecurringAmount] = useState(0)
  const [paymentFrequencySelectedItem, setPaymentFrequencySelectedItem] =
    useState("")
  const [paymentFrequencyError, setPaymentFrequencyError] = useState(false)
  const [recurringStartDate, setRecurringStartDate] = useState(addDays(new Date(), 1))
  const [recurringEndDate, setRecurringEndDate] = useState(addDays(new Date(), 1))
  const [formattedRecurringEndDate, setFormattedRecurringEndDate] = useState("")
  const [planDuration, setPlanDuration] = useState("")
  const [planCounter, setPlanCounter] = useState(0)
  const [recurringIteration, setRecurringIteration] = useState(0)

  const [transactionType, setTransactionType] = useState("")

  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const history = useHistory()

  const calculateRecurringEndDate = (
    startDate: Date,
    value: number,
    frequency: string
  ) => {
    const date = new Date(startDate)
    switch (frequency) {
      case "Weekly":
        setRecurringEndDate(
          new Date(date.setDate(startDate.getDate() + 7 * value))
        )
        break
      case "Fortnightly":
        setRecurringEndDate(
          new Date(date.setDate(startDate.getDate() + 7 * value * 2))
        )
        break
      case "Monthly":
        setRecurringEndDate(
          new Date(date.setMonth(startDate.getMonth() + value))
        )
        break
      case "Bi-monthly":
        setRecurringEndDate(
          new Date(date.setMonth(startDate.getMonth() + value * 2))
        )
        break
      case "Quarterly":
        setRecurringEndDate(
          new Date(date.setMonth(startDate.getMonth() + value * 3))
        )
        break
    }
  }

  const calculatePlanDuration = (amount: number, frequency: string) => {
    const remainingBalance =
      +(
        Number(sessionStorage.getItem("plan_price"))
        + (sessionStorage.getItem("tax_rate") ?
          +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
          : 0)
        - initialDeposit
        - scheduledDepositAmount
      ).toFixed(2)
    let counter = 0

    for (let i = 0; i * amount < remainingBalance; i++) {
      counter++
    }

    if (
      remainingBalance % (amount * counter) !== 0 &&
      (amount * counter) % remainingBalance !== 0
    ) {
      setPaymentFrequencyError(true)
      return
    }

    if (remainingBalance - (recurringAmount || 0) < 0) {
      setPaymentFrequencyError(true)
      return
    }

    setPaymentFrequencyError(false)
    setPlanCounter(counter)
    setRecurringIteration(counter)
    switch (frequency) {
      case "Weekly":
        setPlanDuration(`Plan duration: ${counter} weeks`)
        break
      case "Fortnightly":
        setPlanDuration(`Plan duration: ${counter * 2} weeks`)
        break
      case "Monthly":
        setPlanDuration(`Plan duration: ${counter} months`)
        break
      case "Bi-monthly":
        setPlanDuration(`Plan duration: ${counter * 2} months`)
        break
      case "Quarterly":
        setPlanDuration(`Plan duration: ${counter * 3} months`)
        break
    }
  }

  const formatDate = (date: string) => {
    const [year, month, day]: string[] = date.split("-")
    return [day, month, year].join("/")
  }

  useEffect(() => {
    if (recurringAmount && paymentFrequencySelectedItem) {
      calculatePlanDuration(recurringAmount, paymentFrequencySelectedItem)
    }
  }, [
    recurringAmount,
    paymentFrequencySelectedItem,
    initialDeposit,
    scheduledDepositAmount,
  ])

  useEffect(() => {
    if (recurringStartDate) {
      calculateRecurringEndDate(
        recurringStartDate,
        planCounter,
        paymentFrequencySelectedItem
      )
    }
  }, [recurringStartDate, planCounter, paymentFrequencySelectedItem])

  useEffect(() => {
    if (
      +(
        Number(sessionStorage.getItem("plan_price"))
        + (sessionStorage.getItem("tax_rate") ?
          +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
          : 0)
        - initialDeposit
        - scheduledDepositAmount
      ).toFixed(2)
      < 0
    ) {
      setBalanceError(true)
    } else {
      setBalanceError(false)
    }
  }, [initialDeposit, scheduledDepositAmount])

  useEffect(() => {
    // if (!isScheduledDeposit && !isRecurring) {
    //   setInitialDeposit(0)
    // }
    if (!isScheduledDeposit) {
      setScheduledDepositAmount(0)
      setScheduledDepositDate(addDays(new Date(), 1))
    }
    if (!isRecurring) {
      setRecurringAmount(0)
      setPaymentFrequencySelectedItem("")
      setRecurringStartDate(addDays(new Date(), 1))
      setRecurringEndDate(addDays(new Date(), 1))
    }
  }, [isScheduledDeposit, isRecurring])

  useEffect(() => {
    if (recurringEndDate) {
      setFormattedRecurringEndDate(
        formatDate(recurringEndDate?.toISOString().split("T")[0])
      )
    }
  }, [recurringEndDate])

  const handleProceed = async () => {

    setLoading(true)
    setError("")

    const result = await axios
      .get(`${process.env.REACT_APP_API_URL}admin/v1/additional-percent/get?region=${sessionStorage.getItem("country")}`)
      .then(async (res) => {
        if (res.data.data[0].active) {
          // if plan add percent is not null, plan add percent is in operation
          sessionStorage.setItem("plan_add_percent", res.data.data[0].percent)
        }
        return true
      })
      .catch((err) => {
        setError(err.response.data.message)
        setLoading(false)
        return false
      })
      
    if (!result) {
      return
    }

    // compute total program prices here since Quebec flow skips payment type page
    const totalProgramPrice =
      Number(
        (
          Number(sessionStorage.getItem("plan_price")) +
          +Number(
            sessionStorage.getItem("tax_rate")
              ? Number(
                  (Number(sessionStorage.getItem("plan_price")) *
                    Number(sessionStorage.getItem("tax_rate"))) /
                    100
                )
              : 0
          ).toFixed(2)
        ).toFixed(2)
      ) || Number(sessionStorage.getItem("plan_price"))
    
    sessionStorage.setItem("total_program_price", totalProgramPrice.toString() || "0")

    if (sessionStorage.getItem("plan_add_percent")) {
      const totalProgramPriceAddPercent = sessionStorage.getItem("plan_add_percent")
      ? +Number(
          (
            Number(totalProgramPrice) +
            Number(
              sessionStorage.getItem("plan_add_percent")
                ? Number((Number(totalProgramPrice) * Number(sessionStorage.getItem("plan_add_percent"))) / 100)
                : 0
            )
          ).toFixed(2)
        ).toFixed(2)
      : totalProgramPrice
      
      sessionStorage.setItem("total_program_price_add_percent", totalProgramPriceAddPercent.toString() || "0")
    }

    sessionStorage.setItem(
      "program_start_date",
      startDate?.toISOString().split("T")[0] ?? ""
    )
    sessionStorage.setItem(
      "program_end_date",
      endDate?.toISOString().split("T")[0] ?? ""
    )

    sessionStorage.setItem("stage", stage)
    sessionStorage.setItem("terminal", terminal)
    sessionStorage.setItem("transaction_type", transactionType)
    sessionStorage.setItem("initial_deposit", initialDeposit.toString())

    if (isScheduledDeposit) {
      sessionStorage.setItem("isScheduledDeposit", "Yes")
      sessionStorage.setItem(
        "scheduled_deposit_amount",
        scheduledDepositAmount.toString()
      )
      sessionStorage.setItem(
        "scheduled_deposit_date",
        scheduledDepositDate?.toISOString().split("T")[0]
      )
    }

    if (isRecurring) {
      sessionStorage.setItem("isRecurring", "Yes")
      sessionStorage.setItem("recurring_amount", recurringAmount.toString())
      sessionStorage.setItem(
        "recurring_start_date",
        recurringStartDate?.toISOString().split("T")[0]
      )
      sessionStorage.setItem(
        "recurring_end_date",
        recurringEndDate?.toISOString().split("T")[0]
      )
      recurringIteration
        ? sessionStorage.setItem(
            "recurring_iteration",
            recurringIteration.toString()
          )
        : sessionStorage.setItem("recurring_iteration", "1")

      switch (paymentFrequencySelectedItem) {
        case "Weekly":
          sessionStorage.setItem("interval", "week")
          sessionStorage.setItem("interval_count", "1")
          sessionStorage.setItem("frequency", "Weekly")
          break
        case "Fortnightly":
          sessionStorage.setItem("interval", "week")
          sessionStorage.setItem("interval_count", "2")
          sessionStorage.setItem("frequency", "Fortnightly")
          break
        case "Monthly":
          sessionStorage.setItem("interval", "month")
          sessionStorage.setItem("interval_count", "1")
          sessionStorage.setItem("frequency", "Monthly")
          break
        case "Bi-monthly":
          sessionStorage.setItem("interval", "month")
          sessionStorage.setItem("interval_count", "2")
          sessionStorage.setItem("frequency", "Bi-monthly")
          break
        case "Quarterly":
          sessionStorage.setItem("interval", "month")
          sessionStorage.setItem("interval_count", "3")
          sessionStorage.setItem("frequency", "Quarterly")
          break
      }

    }

    if (
      (initialDeposit || isScheduledDeposit || isRecurring) &&
      ((Number(sessionStorage.getItem("plan_price"))
        + (sessionStorage.getItem("tax_rate") ?
          +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
          : 0)
        - initialDeposit
        - scheduledDepositAmount == 0) || 
        isRecurring
      )
    ) {
      sessionStorage.setItem("isFullPayment", "Yes")
    }
    else {
      sessionStorage.setItem("isFullPayment", "No")
    }

    sessionStorage.setItem("isCreateOpportunity", "Yes")

    sessionStorage.setItem("isCreateOpportunity", "Yes")
    sessionStorage.setItem("createOpportunitySkipped", "Yes")

    setLoading(false)

    if (terminal === "EFT") {
      history.push("/payment-portal/eft-upload")
    }
    else {
      history.push("/payment-portal/payment-type")
    }
    
  }

  const handleEndDate = (startDate: Date, value: number) => {
    setSelectedDuration(value)
    const date = new Date(startDate)
    setEndDate(new Date(date.setMonth(startDate.getMonth() + value)))
  }

  useEffect(() => {
    if (!startDate || !endDate || !stage || !terminal || !transactionType) {
      setNextButtonDisabled(true)
      return
    }

    if (!isScheduledDeposit && !isRecurring && initialDeposit <= 0
    ) {
      setNextButtonDisabled(true)
      return
    }

    if (
      (isScheduledDeposit || isRecurring) &&
      (balanceError || Number(sessionStorage.getItem("plan_price"))
        + (sessionStorage.getItem("tax_rate") ?
          +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
          : 0)
        - initialDeposit
        - scheduledDepositAmount < 0)
    ) {
      setNextButtonDisabled(true)
      return
    }

    if (isScheduledDeposit &&
      (
        !scheduledDepositAmount ||
        !scheduledDepositDate
      )) {
      setNextButtonDisabled(true)
      return
    }

    if (isRecurring &&
      (
        !recurringAmount ||
        !paymentFrequencySelectedItem ||
        paymentFrequencyError ||
        !recurringStartDate ||
        !recurringEndDate
      )) {
      setNextButtonDisabled(true)
      return
    }

    setNextButtonDisabled(false)

  }, [
    startDate,
    endDate,
    terminal,
    stage,
    transactionType,
    isScheduledDeposit,
    isRecurring,
    initialDeposit,
    balanceError,
    scheduledDepositAmount,
    scheduledDepositDate,
    recurringAmount,
    paymentFrequencySelectedItem,
    paymentFrequencyError,
    recurringStartDate,
    recurringEndDate,
  ])

  useEffect(() => {
    const removeKeys = [
      "opportunity_id",
      "isFullPayment",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))
  }, [])

  return (
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <h3 className="text-3xl leading-6 font-medium text-gray-900">
        Create Opportunity
      </h3>
      <button
        className="absolute top-0 right-4 bg-3FB1FC rounded-xl text-white font-semibold p-3 hover:bg-3FB1FC_hover transition-all duration-200 hover:scale-[1.03]"
        onClick={() => setHelpVideoOpen(true)}
      >
        Need Help?
      </button>
      <HelpVideo open={helpVideoOpen} setOpen={setHelpVideoOpen} />

      <div className="flex gap-x-8">
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">

            <div className="w-full px-4 sm:px-6 lg:px-8 my-6">
              <div className="block text-sm font-medium text-gray-700">
                Enter Initial Deposit ($)
              </div>
              {sessionStorage.getItem("plan_price") &&
                +(
                  Number(sessionStorage.getItem("plan_price"))
                  + (sessionStorage.getItem("tax_rate") ?
                    +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
                    : 0)
                  - initialDeposit
                  - scheduledDepositAmount
                ).toFixed(2)
                < 0
                ? (
                  <span className="text-red-600">
                    Please enter an amount less than the total plan price.
                  </span>
                ) : scheduledDepositAmount ? (
                  <span className="text-green-600">
                    Remaining balance after scheduled deposit (tax included): $
                    {Math.abs(
                      Number(sessionStorage.getItem("plan_price"))
                      + (sessionStorage.getItem("tax_rate") ?
                        +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
                        : 0)
                      - initialDeposit
                      - scheduledDepositAmount
                    ).toFixed(2)}
                  </span>
                ) : (
                  <span className="text-green-600">
                    Remaining balance after initial deposit (tax included): $
                    {Math.abs(
                      Number(sessionStorage.getItem("plan_price"))
                      + (sessionStorage.getItem("tax_rate") ?
                        +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
                        : 0)
                      - initialDeposit
                      - scheduledDepositAmount
                    ).toFixed(2)}
                  </span>
                )}
              <input
                type="number"
                className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                value={initialDeposit}
                onChange={(e) => {
                  if (e.target.value.startsWith("0")) e.target.value = e.target.value.replace(/^0+/, "")
                  setInitialDeposit(+e.target.value)
                }}
                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                  (e.target as HTMLInputElement).blur()
                }
              />
            </div>
            
            <div className="mb-8 w-full px-4 sm:px-6 lg:px-8 flex justify-around">
                
              <div className="relative flex items-start">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor="region_access"
                    className="font-medium text-gray-700 select-none"
                  >
                    Scheduled Deposit
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id="AU"
                    name="region_access"
                    type="checkbox"
                    className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                    value="AU"
                    checked={isScheduledDeposit}
                    onClick={() => setIsScheduledDeposit(!isScheduledDeposit)}
                  />
                </div>
              </div>
              <div className="relative flex items-start">
                <div className="min-w-0 flex-1 text-sm">
                  <label
                    htmlFor="region_access"
                    className="font-medium text-gray-700 select-none"
                  >
                    Recurring Payment
                  </label>
                </div>
                <div className="ml-3 flex items-center h-5">
                  <input
                    id="CA"
                    name="region_access"
                    type="checkbox"
                    className="h-4 w-4 text-navy-theme border-gray-300 rounded focus:ring-0 ring-offset-0 focus:shadow-none"
                    value="CA"
                    checked={isRecurring}
                    onClick={() => setIsRecurring(!isRecurring)}
                  />
                </div>
              </div>
            </div>
            
            {isScheduledDeposit && (
              <>
                <div className="w-full flex justify-between mb-4">
                  <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
                    <div className="block text-sm font-medium text-gray-700">
                      Enter Scheduled Deposit Amount ($)
                    </div>
                    <input
                      type="number"
                      className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                      value={scheduledDepositAmount}
                      onChange={(e) => {
                        if (e.target.value.startsWith("0")) e.target.value = e.target.value.replace(/^0+/, "")
                        setScheduledDepositAmount(+e.target.value)
                      }}
                      onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                        (e.target as HTMLInputElement).blur()
                      }
                    />
                  </div>
                  <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
                    <div className="block text-sm font-medium text-gray-700">
                      Select Scheduled Deposit Date
                    </div>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select deposit date"
                      selected={scheduledDepositDate}
                      onChange={(date) => setScheduledDepositDate(date as Date)}
                      showPopperArrow={false}
                      showMonthDropdown
                      useShortMonthInDropdown
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={5}
                      minDate={addDays(new Date(), 1)}
                      className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4  items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                    />
                  </div>
                </div>
              </>
            )}
            {isRecurring &&
              Number(sessionStorage.getItem("plan_price"))
              + (sessionStorage.getItem("tax_rate") ?
                +Number(Number(sessionStorage.getItem("plan_price")) * Number(sessionStorage.getItem("tax_rate")) / 100).toFixed(2)
                : 0)
              - initialDeposit
              - scheduledDepositAmount >
              0 && (
                <div className="w-full flex justify-between mb-4">
                  <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
                    <div className="block text-sm font-medium text-gray-700">
                      Enter Recurring Amount (
                      {sessionStorage.getItem("currency") === "EUR"
                        ? "€"
                        : sessionStorage.getItem("currency") === "GBP"
                          ? "£"
                          : "$"}
                      )
                    </div>
                    <input
                      type="number"
                      className="mt-2 rounded-md border-c4c4c4 w-full h-12 bg-E0E0E0 flex pl-2 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                      value={recurringAmount}
                      onChange={(e) => {
                        if (e.target.value.startsWith("0")) e.target.value = e.target.value.slice(1)
                        setRecurringAmount(+e.target.value)
                      }}
                      onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                        (e.target as HTMLInputElement).blur()
                      }
                    />
                  </div>
                  <div className="my-4 sm:mt-0 w-1/2 px-4 sm:px-6 lg:px-8">
                    <div className="block text-sm font-medium text-gray-700">
                      Select Payment Frequency
                    </div>
                    <PaymentFrequencySelectMenu
                      paymentFrequencyList={[
                        { id: "Weekly", value: "Weekly" },
                        { id: "Fortnightly", value: "Fortnightly" },
                        { id: "Monthly", value: "Monthly" },
                        { id: "Bi-monthly", value: "Bi-monthly" },
                        { id: "Quarterly", value: "Quarterly" },
                      ]}
                      paymentFrequencySelectedItem={paymentFrequencySelectedItem}
                      setPaymentFrequencySelectedItem={
                        setPaymentFrequencySelectedItem
                      }
                    />
                  </div>
                </div>
              )}
            {paymentFrequencyError && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-4">
                <div className="text-red-600">
                  Current payment frequency will result in an uneven or negative
                  balance. Please enter a valid recurring amount.
                </div>
              </div>
            )}
            {recurringAmount > 0 && paymentFrequencySelectedItem && (
              <div className="w-full px-4 sm:px-6 lg:px-8 mb-8">
                <label
                  htmlFor="program_start_date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Start Date of Recurring Payment
                </label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select start date of recurring payment"
                  selected={recurringStartDate}
                  onChange={(date) => setRecurringStartDate(date as Date)}
                  showPopperArrow={false}
                  showMonthDropdown
                  useShortMonthInDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={5}
                  minDate={addDays(new Date(), 1)}
                  className="my-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4  items-center pl-6 text-828282 border border-transparent focus:outline-none focus:ring-0 font-medium"
                />

                <div className="mb-4">
                  <div className="text-green-600">{planDuration}</div>
                  {recurringStartDate && (
                    <div className="text-green-600">
                      End date: {formattedRecurringEndDate}
                    </div>
                  )}
                </div>

              </div>
            )}


            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label
                htmlFor="program_start_date"
                className="block text-sm font-medium text-gray-700"
              >
                Select Start Date of Program
              </label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Select start date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showPopperArrow={false}
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={5}
                className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border items-center pl-6 text-828282 border-transparent focus:outline-none focus:ring-0 font-medium"
              />
            </div>


            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label
                htmlFor="program_end_date"
                className="block text-sm font-medium text-gray-700"
              >
                Select End Date of Program
              </label>
              <div className="flex my-3 gap-x-4">
                <button
                  className={`${selectedDuration === 1
                    ? "bg-FF5733"
                    : startDate
                      ? "bg-navy-theme hover:bg-navy-theme_hover"
                      : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                  onClick={() => {
                    if (startDate) {
                      handleEndDate(startDate, 1)
                    }
                  }}
                >
                  1 month
                </button>
                <button
                  className={`${selectedDuration === 2
                    ? "bg-FF5733"
                    : startDate
                      ? "bg-navy-theme hover:bg-navy-theme_hover"
                      : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                  onClick={() => {
                    if (startDate) {
                      handleEndDate(startDate, 2)
                    }
                  }}
                >
                  2 months
                </button>
                <button
                  className={`${selectedDuration === 3
                    ? "bg-FF5733"
                    : startDate
                      ? "bg-navy-theme hover:bg-navy-theme_hover"
                      : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                  onClick={() => {
                    if (startDate) {
                      handleEndDate(startDate, 3)
                    }
                  }}
                >
                  3 months
                </button>
                <button
                  className={`${selectedDuration === 6
                    ? "bg-FF5733"
                    : startDate
                      ? "bg-navy-theme hover:bg-navy-theme_hover"
                      : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                  onClick={() => {
                    if (startDate) {
                      handleEndDate(startDate, 6)
                    }
                  }}
                >
                  6 months
                </button>
                <button
                  className={`${selectedDuration === 12
                    ? "bg-FF5733"
                    : startDate
                      ? "bg-navy-theme hover:bg-navy-theme_hover"
                      : "bg-E0E0E0 text-828282 cursor-auto"
                    } text-white p-2 rounded-md`}
                  onClick={() => {
                    if (startDate) {
                      handleEndDate(startDate, 12)
                    }
                  }}
                >
                  12 months
                </button>
              </div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                placeholderText="Select end date"
                selected={endDate}
                onChange={(date) => {
                  setSelectedDuration(0)
                  setEndDate(date)
                }}
                showPopperArrow={false}
                showMonthDropdown
                useShortMonthInDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={5}
                className="mt-2 bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border items-center pl-6 text-828282 border-transparent focus:outline-none focus:ring-0 font-medium"
              />
            </div>
            
            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label
                htmlFor="transaction_type"
                className="block text-sm font-medium text-gray-700"
              >
                Transaction Type
              </label>
              <TransactionTypeSelectMenu
                setTransactionType={setTransactionType}
              />
            </div>

            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label
                htmlFor="stage"
                className="block text-sm font-medium text-gray-700"
              >
                Stage
              </label>
              <StageSelectMenu setStage={setStage} />
            </div>

            <div className="w-full px-4 sm:px-6 lg:px-8 mb-6">
              <label
                htmlFor="terminal"
                className="block text-sm font-medium text-gray-700"
              >
                Terminal
              </label>
              <TerminalSelectMenu setTerminal={setTerminal} />
            </div>
          </div>

          {error && (
            <div className="text-center text-lg text-E84545 font-bold">
              {error}
            </div>
          )}

          <div className="flex justify-between w-full px-4 sm:px-6 lg:px-8">
            <button
              type="button"
              className={`text-white bg-FF5733 hover:bg-FF5733_hover my-6 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
              onClick={() => history.goBack()}
            >
              Back
            </button>
            <div className="relative">
              {loading && (
                <img
                  width={40}
                  className="inline absolute left-[6px] top-[30px]"
                  src={spinnerBlack}
                  alt="Loading..."
                />
              )}
              <button
                type="button"
                className={`${
                  nextButtonDisabled || loading
                    ? "bg-E0E0E0 text-828282"
                    : "text-white bg-FF5733 hover:bg-FF5733_hover"
                } my-6 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                onClick={() => handleProceed()}
                disabled={nextButtonDisabled}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      <InfoBlock />
    </div>
  )
}

export default CreateOpportunityQuebec
