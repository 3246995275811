import { FC, useState } from "react"
import { useHistory } from "react-router-dom"
import HelpVideo from "../help-video/HelpVideo"
import ProgramInfo from "./program-info/ProgramInfo"
import ProgressTitle from "./progress-ui/ProgressTitle"
import ProgressBar from "./progress-ui/ProgressBar"
import spinnerBlack from "../../../../assets/common/spinnerBlack.svg"
import InfoBlock from "./info-block/InfoBlock"

interface LayoutProps {
  pageNumber: number
  nextButtonDisabled: boolean
  handleProceed: () => void
  backButtonDisabled?: boolean
  checkingPayment?: boolean
  existingCardSelected?: boolean
  handlePayment?: () => void
  updateCardProcessing?: boolean
}

const Layout: FC<LayoutProps> = ({
  children,
  pageNumber,
  nextButtonDisabled,
  handleProceed,
  backButtonDisabled = false,
  checkingPayment = false,
  existingCardSelected = false,
  handlePayment,
  updateCardProcessing = false,
}) => {
  const [helpVideoOpen, setHelpVideoOpen] = useState(false)
  const history = useHistory()

  return (
    <>
      <HelpVideo open={helpVideoOpen} setOpen={setHelpVideoOpen} />
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ProgramInfo />
        <h3 className="text-3xl leading-6 font-medium text-gray-900">
          Payment Portal
        </h3>
        <button
          className="absolute top-0 right-4 bg-3FB1FC rounded-xl text-white font-semibold p-3 hover:bg-3FB1FC_hover transition-all duration-200 hover:scale-[1.03]"
          onClick={() => setHelpVideoOpen(true)}
        >
          Need Help?
        </button>
        <div className="h-full w-full md:w-40r mx-auto bg-white border-1 border-c4c4c4 rounded-2xl mt-65">
          <div className="w-full h-full flex flex-col justify-evenly items-center">
            <div className="flex flex-col my-10">
              <ProgressTitle pageNumber={pageNumber} />
              <ProgressBar pageNumber={pageNumber} />
            </div>
            {children}
            <div className="flex justify-between w-full px-4 sm:px-6 lg:px-8">
              <button
                type="button"
                className={`${backButtonDisabled
                  ? "bg-E0E0E0 text-828282"
                  : "text-white bg-FF5733 hover:bg-FF5733_hover"
                  } my-9 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                onClick={() => history.goBack()}
                disabled={backButtonDisabled}
              >
                Back
              </button>
              {pageNumber !== 9 &&
                pageNumber !== 10 &&
                (checkingPayment ? (
                  <div className="relative">
                    {checkingPayment && (
                      <img
                        width={40}
                        className="inline absolute left-[4px] top-[44px]"
                        src={spinnerBlack}
                        alt="Loading..."
                      />
                    )}
                    <button
                      type="button"
                      className={
                        "bg-E0E0E0 text-black my-9 w-32 sm:w-56 h-14 text-center pl-5 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
                      }
                      disabled={nextButtonDisabled}
                    >
                      Checking payment...
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className={`${nextButtonDisabled
                      ? "bg-E0E0E0 text-828282"
                      : "text-white bg-FF5733 hover:bg-FF5733_hover"
                      } my-9 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    onClick={() => handleProceed()}
                    disabled={nextButtonDisabled}
                  >
                    Proceed
                  </button>
                ))}
              {pageNumber === 9 &&
                (existingCardSelected ? (
                  <div className="relative">
                    <button
                      type="button"
                      className={`${nextButtonDisabled
                        ? "bg-E0E0E0 text-828282 cursor-auto"
                        : "text-white bg-FF5733 hover:bg-FF5733_hover cursor-pointer"
                        } my-9 w-32 sm:w-72 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                      onClick={() => {
                        if (!nextButtonDisabled && handlePayment) {
                          handlePayment()
                        }
                      }}
                    >
                      Proceed
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="text-white bg-FF5733 hover:bg-FF5733_hover my-9 w-32 sm:w-72 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0"
                    onClick={() => {
                      handleProceed()
                    }}
                  >
                    Enter new payment method
                  </button>
                ))}
              {pageNumber === 10 && (
                <div className="relative">
                  {updateCardProcessing && (
                    <img
                      width={40}
                      className="inline absolute top-11 left-4"
                      src={spinnerBlack}
                      alt="Loading..."
                    />
                  )}
                  <button
                    type="button"
                    className={`${updateCardProcessing
                      ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto"
                      : "bg-FF5733 hover:bg-FF5733_hover text-white"
                      } my-9 w-32 sm:w-56 h-14 text-center px-6 py-3 border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                    onClick={() => {
                      if (!nextButtonDisabled) {
                        handleProceed()
                      }
                    }}
                  >
                    {updateCardProcessing ? "Processing..." : "Proceed"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <InfoBlock />
    </>
  )
}

export default Layout
