import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  ChangeEvent,
} from "react"
import { Link } from "react-router-dom"
import { ReactComponent as Search } from "../../../../../assets/common/search.svg"
import spinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"
import CancelSubscriptionModal from "./CancelSubscriptionModal"
import SubscriptionModal from "./SubscriptionModal"

type PropsType = {
  customerList: Customer[]
  setCustomerList: (args: Customer[]) => void
  selectedItem: string
  setSelectedItem: (args: string) => void
  searchLead: () => void
  dropdownOpen: boolean
  setDropdownOpen: (args: boolean) => void
  setNextButtonDisabled: (args: boolean) => void
  setOpportunitiesLoading: (args: boolean) => void
  opportunityData: Opportunity[]
  setOpportunityData: (args: Opportunity[]) => void
  planSelected: number
  setPlanSelected: (args: number) => void
  zohoHidden: boolean
  setZohoHidden: (args: boolean) => void
  opportunityHidden: boolean
  setOpportunityHidden: (args: boolean) => void
  setOpportunitiesQueryError: (args: string) => void
}

type Customer = {
  first_name: string
  last_name: string
  email: string
  phone: string
  mobile: string
  lead_id: string
  lead_status: string
  lead_created_time: string
  lead_modified_time: string
  lead_source: string
  date_of_birth: string
  contact_id?: string
}

type Opportunity = {
  stripe_subscription?: {
    id: string
    start_date_unix: number
    end_date_unix: number
    amount: number
    frequency: string
  }
  stripe_scheduled?: string
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
  additional_price_percent?: number
}

const CustomerSearchMenu: React.FC<PropsType> = ({
  customerList,
  setCustomerList,
  selectedItem,
  setSelectedItem,
  searchLead,
  dropdownOpen,
  setDropdownOpen,
  setNextButtonDisabled,
  setOpportunitiesLoading,
  opportunityData,
  setOpportunityData,
  planSelected,
  setPlanSelected,
  zohoHidden,
  setZohoHidden,
  opportunityHidden,
  setOpportunityHidden,
  setOpportunitiesQueryError,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null)
  const region = sessionStorage.getItem("country")
  const email = sessionStorage.getItem("email")
  const module = sessionStorage.getItem("module")

  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] = useState(false)
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
  
  const openHandler = (): void => {
    if (selectedItem !== "") {
      setDropdownOpen(!dropdownOpen)
    }
  }

  const selectLeadHandler = (id: string) => {
    const selected = customerList.find((el) => el.lead_id === id)
    sessionStorage.setItem("email", selected!.email)
    sessionStorage.setItem("id", selected!.lead_id)
    sessionStorage.setItem(
      "full_name",
      selected!.first_name + " " + selected!.last_name
    )
    sessionStorage.setItem("mobile", selected!.mobile)
    sessionStorage.setItem("lead_source", selected!.lead_source)
    sessionStorage.setItem("date_of_birth", selected!.date_of_birth)
    setSelectedItem(selected!.first_name + " " + selected!.last_name)
    setDropdownOpen(false)
    setCustomerList([])
    setZohoHidden(false)
    setNextButtonDisabled(false)
    setOpportunityData([])
  }

  const selectContactHandler = (id: string) => {
    const selected = customerList.find((el) => el.contact_id === id)
    sessionStorage.setItem("email", selected!.email)
    sessionStorage.setItem("id", selected!.contact_id as string)
    sessionStorage.setItem(
      "full_name",
      selected!.first_name + " " + selected!.last_name
    )
    sessionStorage.setItem("mobile", selected!.mobile)
    sessionStorage.setItem("contact_id", selected!.contact_id as string)

    setSelectedItem(selected!.first_name + " " + selected!.last_name)
    setCustomerList([])
    setZohoHidden(true)
    setOpportunityHidden(true)
    setOpportunityData([])
    getOpportunity(selected!.contact_id as string)
    setDropdownOpen(false)
  }

  const getOpportunity = async (contact_id: string) => {
    setOpportunityData([])
    setOpportunitiesLoading(true)
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/get-opportunity`,
        {
          contact_id: contact_id,
          country: region,
        }
      )
      .then((res) => {
        const {
          data: { data },
        } = res

        setOpportunityData(data)
      })
      .catch((err) => {
        setOpportunitiesQueryError(err?.response?.data?.message)
      })

    setOpportunitiesLoading(false)
    setZohoHidden(false)
    setOpportunityHidden(false)
    setInvoiceIndex(99)
    setInvoiceMessage("")
  }

  const createInvoice = async (opportunityId: string, index: number) => {
    setInvoiceMessage("")
    setInvoiceLoading(true)
    setInvoiceIndex(index)
    await axios
      .post(`${process.env.REACT_APP_API_URL}admin/v1/invoice/create`, {
        country: region,
        email: email,
        opportunity_id: opportunityId,
      })
      .then((res) => {
        setInvoiceMessage(res.data.message)
        setInvoiceSuccess(true)
        getOpportunity(sessionStorage.getItem("contact_id") as string)
      })
      .catch((err) => {
        setInvoiceMessage(err.response.data.message)
        setInvoiceSuccess(false)
      })
    setInvoiceLoading(false)
  }

  const [invoiceLoading, setInvoiceLoading] = useState(false)
  const [invoiceIndex, setInvoiceIndex] = useState(99)
  const [invoiceSuccess, setInvoiceSuccess] = useState(true)
  const [invoiceMessage, setInvoiceMessage] = useState("")

  const handlePlan = (index: number, opportunity: Opportunity) => {
    sessionStorage.setItem("opportunity_id", opportunity.opportunity_id)
    sessionStorage.setItem("card_name", opportunity.name_on_card)
    sessionStorage.setItem("last_four_digits", opportunity.last_four_digit)
    sessionStorage.setItem("card_brand", opportunity.brand)
    sessionStorage.setItem("plan_name", opportunity.plan_info?.name)
    sessionStorage.setItem("plan_id", opportunity.plan_info?.id)
    sessionStorage.setItem("total_price", opportunity.total_price.toString())
    sessionStorage.setItem(
      "amount_paid",
      String(opportunity.total_price - opportunity.amount_paid)
    )
    sessionStorage.setItem(
      "amount_remaining",
      opportunity.amount_paid.toString()
    )
    sessionStorage.setItem("invoice_id", opportunity.invoice_id)
    sessionStorage.setItem("currency", opportunity?.currency)
    sessionStorage.setItem("opportunity_add_percent", opportunity.additional_price_percent?.toString() || "")
    setPlanSelected(index)

    if (region === "PCA") {
      if (opportunity.amount_paid <= 0) {
        setNextButtonDisabled(true)
      } else {
        setNextButtonDisabled(false)
      }
    } else if (!opportunity.invoice_id || !opportunity.invoice_number) {
      setNextButtonDisabled(true)
    } else if (opportunity.amount_paid <= 0) {
      setNextButtonDisabled(true)
    } else {
      setNextButtonDisabled(false)
    }
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setDropdownOpen(false)
      }
    },
    [setDropdownOpen]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedItem(e.target.value)
  }

  useEffect(() => {
    const removeKeys = [
      "createOpportunitySkipped",
    ]
    removeKeys.forEach((key) => sessionStorage.removeItem(key))

    setNextButtonDisabled(true)
  }, [])

  const returnUpgradeOpportunityLink = (opportunityId: string) => {
    const region = () => {
      switch (sessionStorage.getItem("country")) {
        case "AU":
          return "52657875"
        case "CA":
          return "760139034"
        case "NZ":
          return "771947106"
        case "UK":
          return "20067563176"
        case "PCA":
          return "803222882"
        default:
          return ""
      }
    }

    const contactId = sessionStorage.getItem("contact_id")!

    return `/upgrade-opportunity/${region()}/${contactId}/${opportunityId}`
  }

  const returnRenewOpportunityLink = (opportunityId: string) => {
    const region = () => {
      switch (sessionStorage.getItem("country")) {
        case "AU":
          return "52657875"
        case "CA":
          return "760139034"
        case "NZ":
          return "771947106"
        case "UK":
          return "20067563176"
        case "PCA":
          return "803222882"
        default:
          return ""
      }
    }

    const contactId = sessionStorage.getItem("contact_id")!

    return `/renew-opportunity/${region()}/${contactId}/${opportunityId}`
  }

  return (
    <div className="relative mt-2">
      <CancelSubscriptionModal
        open={cancelSubscriptionModalOpen}
        setCancelSubscriptionModalOpen={setCancelSubscriptionModalOpen}
        getOpportunity={getOpportunity}
      />
      <SubscriptionModal
        open={subscriptionModalOpen}
        setOpen={setSubscriptionModalOpen}
        setCancelSubscriptionModalOpen={setCancelSubscriptionModalOpen}
        opportunity_id={sessionStorage.getItem("opportunity_id")!}
        recurring_start_date_unix={
          opportunityData[planSelected]?.stripe_subscription?.start_date_unix
        }
        recurring_end_date_unix={
          opportunityData[planSelected]?.stripe_subscription?.end_date_unix
        }
        recurring_amount={
          opportunityData[planSelected]?.stripe_subscription?.amount
        }
        recurring_frequency={
          opportunityData[planSelected]?.stripe_subscription?.frequency
        }
        scheduled_payment={opportunityData[planSelected]?.stripe_scheduled}
      />
      <div
        className="bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border"
        onClick={openHandler}
        ref={dropDownRef}
      >
        <div className="ml-4 flex items-center justify-center min-w-2.375 w-2.375 h-full">
          <Search />
        </div>
        <div>
          <button
            className="absolute top-3 right-6 font-medium"
            onClick={() => searchLead()}
          >
            Search
          </button>
        </div>
        <input
          className="bg-E0E0E0 flex items-center pl-2 text-828282 w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
          placeholder="Enter the name or email of the customer"
          value={selectedItem}
          onChange={handleChange}
          onKeyPress={(event) => event.key === "Enter" && searchLead()}
        />
        {dropdownOpen && (
          <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-40">
            {customerList.map((item, index) => (
              <div
                className={`${index === 0 ? "" : "border-t border-F0F0F0"
                  } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                key={index}
                onClick={() => {
                  if (item.contact_id) {
                    selectContactHandler(item.contact_id)
                  } else {
                    selectLeadHandler(item.lead_id)
                  }
                }}
              >
                <div className="flex text-sm">
                  <div className="w-36">
                    {item.first_name + " " + item.last_name}
                  </div>
                  <div className="w-64">{item.email?.substring(0, 30)}</div>
                  <div className="">{item.mobile}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* VIEW ZOHO AU, CA, NZ, PCA */}
      {!zohoHidden &&
        (region === "AU" ||
          region === "CA" ||
          region === "NZ" ||
          region === "PCA") && (
          <a
            href={
              sessionStorage.getItem("module") === "LEADS"
                ? `https://crm.zoho.com/crm/${region === "AU"
                  ? "org52657875"
                  : region === "CA"
                    ? "org760139034"
                    : region === "NZ"
                      ? "org771947106"
                      : region === "PCA"
                        ? "org803222882"
                        : ""
                }/tab/Leads/${sessionStorage.getItem("id")}`
                : `https://crm.zoho.com/crm/${region === "AU"
                  ? "org52657875"
                  : region === "CA"
                    ? "org760139034"
                    : region === "NZ"
                      ? "org771947106"
                      : region === "PCA"
                        ? "org803222882"
                        : ""
                }/tab/Contacts/${sessionStorage.getItem("id")}`
            }
            target="_blank"
            rel="noreferrer"
          >
            <button
              type="button"
              className="mt-8 text-white bg-navy-theme w-full h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 hover:bg-navy-theme_hover"
            >
              View in Zoho
            </button>
          </a>
        )}

      {/* VIEW ZOHO UK */}
      {!zohoHidden && region === "UK" && (
        <a
          href={
            sessionStorage.getItem("module") === "LEADS"
              ? `https://crm.zoho.eu/crm/org20067563176/tab/Leads/${sessionStorage.getItem(
                "id"
              )}`
              : `https://crm.zoho.eu/crm/org20067563176/tab/Contacts/${sessionStorage.getItem(
                "id"
              )}`
          }
          target="_blank"
          rel="noreferrer"
        >
          <button
            type="button"
            className="mt-8 text-white bg-navy-theme w-full h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 hover:bg-navy-theme_hover"
          >
            View in Zoho
          </button>
        </a>
      )}

      {/* CREATE NEW OPPORTUNITY */}
      {!opportunityHidden && module === "CONTACTS" && (
        sessionStorage.getItem("country") === "CA" ? (
          <Link to="/payment-portal/province-select">
            <button
              type="button"
              className="mt-4 text-white bg-navy-theme w-full h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 hover:bg-navy-theme_hover"
              onClick={() => {
                sessionStorage.removeItem("opportunity_id")
                sessionStorage.setItem("isCreateOpportunity", "Yes")
              }}
            >
              Create New Opportunity
            </button>
          </Link>
        ) : (
          <Link to="/payment-portal/create-opportunity">
            <button
              type="button"
              className="mt-4 text-white bg-navy-theme w-full h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0 hover:bg-navy-theme_hover"
              onClick={() => {
                sessionStorage.removeItem("opportunity_id")
              }}
            >
              Create New Opportunity
            </button>
          </Link>
        )
      )}

      {/* OPPORTUNITY */}
      {!opportunityHidden && opportunityData.length > 0 && (
        <div className={`text-lg font-bold text-gray-700 mt-4`}>
          Select opportunity:
        </div>
      )}

      {opportunityData.map((opportunity: Opportunity, index: number) => (
        <div key={index}>
          <div
            className={`border-2 divide-y divide-gray-200 overflow-hidden shadow rounded-lg mt-2 p-4 relative cursor-pointer ${(opportunity.amount_paid <= 0 ||
              !opportunity.invoice_id ||
              !opportunity.invoice_number) &&
              "cursor-auto"
              }
            ${region === "PCA" &&
                planSelected === index &&
                opportunity.amount_paid > 0
                ? "bg-white"
                : "bg-gray-200"
              } 
            ${region !== "PCA" &&
                planSelected === index &&
                opportunity.amount_paid > 0 &&
                opportunity.invoice_id &&
                opportunity.invoice_number
                ? "bg-white"
                : "bg-gray-200"
              } 
            `}
            onClick={() => handlePlan(index, opportunity)}
          >
            <div className="ml-4 grid grid-rows-6 grid-cols-2 gap-y-1 grid-flow-col">
              <p className="text-sm font-semibold text-gray-500 ">
                Name:{" "}
                <span className="font-normal">
                  {opportunity.opportunity_name}
                </span>
              </p>
              {sessionStorage.getItem("country") !== "UK" && (
                <p className="text-sm font-semibold text-gray-500">
                  Enquiry:{" "}
                  <span className="font-normal">
                    {opportunity.enquiry || "Unavailable"}
                  </span>
                </p>
              )}
              <p className="text-sm font-semibold text-gray-500">
                Opp ID:{" "}
                <span className="font-normal">
                  {opportunity.opportunity_id}
                </span>
              </p>
              {region !== "PCA" && (
                <>
                  <p className="text-sm font-semibold text-gray-500">
                    Invoice ID:{" "}
                    <span className="font-normal">
                      {opportunity.invoice_id || "Unavailable"}
                    </span>
                  </p>
                  <p className="text-sm font-semibold text-gray-500">
                    Invoice #:{" "}
                    <span className="font-normal">
                      {opportunity.invoice_number || "Unavailable"}
                    </span>
                  </p>
                </>
              )}
              <p className="text-sm font-semibold text-gray-500">
                Plan Name:{" "}
                <span className="font-normal">
                  {opportunity.plan_info?.name}
                </span>
              </p>
              <p className="text-sm font-semibold text-gray-500">
                Total price:{" "}
                {opportunity.total_price ? (
                  <span className="font-normal">
                    {opportunity.currency === "EUR"
                      ? "€"
                      : opportunity.currency === "GBP"
                        ? "£"
                        : "$"}
                    {opportunity.total_price.toFixed(2)}
                  </span>
                ) : (
                  <span className="font-normal">Unavailable</span>
                )}
              </p>
              <p className="text-sm font-semibold text-gray-500">
                Amount paid:{" "}
                <span className="font-normal">
                  {opportunity.currency === "EUR"
                    ? "€"
                    : opportunity.currency === "GBP"
                      ? "£"
                      : "$"}
                  {(opportunity.total_price - opportunity.amount_paid)?.toFixed(
                    2
                  )}
                </span>
              </p>
              {opportunity.amount_paid == 0 ? (
                <p className="text-sm font-bold text-3fc69d">Paid in full</p>
              ) : opportunity.amount_paid < 0 ? (
                <p className="text-sm font-bold text-E84545">
                  Customer overcharged{" "}
                  {opportunity?.currency === "EUR"
                    ? "€"
                    : opportunity?.currency === "GBP"
                      ? "£"
                      : "$"}
                  {Number(String(opportunity.amount_paid).substring(1)).toFixed(
                    2
                  )}
                </p>
              ) : (
                <p className="text-sm font-semibold text-gray-500">
                  Amount remaining:{" "}
                  <span className="font-normal">
                    {opportunity.currency === "EUR"
                      ? "€"
                      : opportunity.currency === "GBP"
                        ? "£"
                        : "$"}
                    {opportunity.amount_paid?.toFixed(2)}
                  </span>
                </p>
              )}
              <span>
                <Link
                  to={returnUpgradeOpportunityLink(opportunity.opportunity_id)}
                >
                  <button 
                    className="text-sm font-semibold text-white bg-navy-theme hover:bg-navy-theme_hover text-center py-1 rounded-md w-200"
                  >
                    Upgrade Opportunity
                  </button>
                </Link>
              </span>
              <span>
                <Link
                  to={returnRenewOpportunityLink(opportunity.opportunity_id)}
                >
                  <button 
                    className="text-sm font-semibold text-white bg-navy-theme hover:bg-navy-theme_hover text-center py-1 rounded-md w-200"
                  >
                    Renew Opportunity
                  </button>
                </Link>
              </span>
              {sessionStorage.getItem("country") === "CA" && (
                <span>
                  <button 
                    className={`${
                      (!opportunity.stripe_subscription && !opportunity.stripe_scheduled)
                        ? "bg-gray-300 hover:bg-gray-300 text-black cursor-auto"
                        : "bg-3fc69d hover:bg-3fc69d_hover text-white"
                    } text-sm font-semibold text-center py-1 rounded-md w-200`}
                    onClick={() => setSubscriptionModalOpen(true)}
                    disabled={(!opportunity.stripe_subscription && !opportunity.stripe_scheduled)}
                  >
                    View Subscription
                  </button>
                </span>
              )}
              <div
                className={`h-full w-4 absolute top-0 left-0 ${planSelected === index && opportunity.amount_paid > 0
                  ? "bg-3FB1FC"
                  : "bg-navy-theme"
                  } ${opportunity.amount_paid == 0 && "bg-3fc69d"} 
                ${opportunity.amount_paid < 0 && "bg-E84545"}
                ${(!opportunity.invoice_id || !opportunity.invoice_number) &&
                  region !== "PCA" &&
                  "bg-E84545"
                  }`}
              />
            </div>
            {(!opportunity.invoice_id || !opportunity.invoice_number) &&
              region !== "PCA" && (
                <>
                  <p className="ml-4 mt-4 text-sm font-bold text-E84545">
                    Invoice data is unavailable within the CRM. Please click on
                    the &quot;Create Invoice&quot; button to proceed.
                  </p>
                  {invoiceMessage && index === invoiceIndex ? (
                    <button
                      className={`w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-auto ${invoiceSuccess ? "bg-3fc69d" : "bg-E84545"
                        } text-white rounded-lg h-10`}
                    >
                      {invoiceMessage}
                    </button>
                  ) : (
                    <>
                      {invoiceLoading && index === invoiceIndex && (
                        <img
                          width={40}
                          className="absolute left-[194px] bottom-4 invert border-none"
                          src={spinnerBlack}
                          alt="Loading..."
                        />
                      )}
                      <button
                        onClick={() =>
                          createInvoice(opportunity.opportunity_id, index)
                        }
                        className="w-full mx-2 mt-4 text-sm font-semibold shadow-sm cursor-pointer bg-navy-theme text-white rounded-lg h-10"
                      >
                        {invoiceLoading && index === invoiceIndex
                          ? "Creating invoice..."
                          : "Create Invoice"}
                      </button>
                    </>
                  )}
                </>
              )}
          </div>
        </div>
      ))}
    </div>

  )
}

export default CustomerSearchMenu
