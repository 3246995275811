import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Layout from "../../components/layout/Layout"
import PaymentTypeSelectMenu from "./components/PaymentTypeSelectMenu"
import InsuranceConfirmationModal from "./components/InsuranceConfirmationModal"
import axios from "axios"

const PaymentType = () => {
  const history = useHistory()

  let paymentTypeList;
  if (sessionStorage.getItem("country") === "PCA" && sessionStorage.getItem("module") === "CONTACTS") {
    paymentTypeList = [{ id: "Card" }, { id: "EFT" }, { id: "Insurance" }]
  }
  else if (sessionStorage.getItem("country") === "AU" && sessionStorage.getItem("module") === "CONTACTS") {
    paymentTypeList = [{ id: "Card" }, { id: "EFT" }, { id: "Payment Link"}]
  } 
  else {
    paymentTypeList = [{ id: "Card" }, { id: "EFT" }]
  }
  console.log(sessionStorage.getItem("country"))
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true)
  const [paymentTypeSelectedItem, setPaymentTypeSelectedItem] = useState(
    "Select payment type"
  )
  const [checkingPayment, setCheckingPayment] = useState(false)
  const [checkPaymentSuccess, setCheckPaymentSuccess] = useState("")
  const [
    insuranceConfirmationModalOpen,
    setInsuranceConfirmationModalOpen,
  ] = useState(false)

  const handleProceed = async () => {

    setCheckingPayment(true)

    if (
      sessionStorage.getItem("module") === "LEADS" ||
      sessionStorage.getItem("isCreateOpportunity") === "Yes" ||
      sessionStorage.getItem("isUpgradeOpportunity") === "Yes" ||
      sessionStorage.getItem("isRenewOpportunity") === "Yes"
    ) {
      const result = await axios
        .get(`${process.env.REACT_APP_API_URL}admin/v1/additional-percent/get?region=${sessionStorage.getItem("country")}`)
        .then(async (res) => {
          if (res.data.data[0].active) {
            // if plan add percent is not null, plan add percent is in operation
            sessionStorage.setItem("plan_add_percent", res.data.data[0].percent)
          }
          return true
        })
        .catch((err) => {
          setCheckPaymentSuccess("ERROR")
          sessionStorage.setItem("check_payment_msg", err.response.data.message)
          setCheckingPayment(false)
          return false
        })

      if (!result) {
        return
      }
    }

    const totalProgramPrice =
      Number(
        (
          Number(sessionStorage.getItem("plan_price")) +
          +Number(
            sessionStorage.getItem("tax_rate")
              ? Number(
                  (Number(sessionStorage.getItem("plan_price")) *
                    Number(sessionStorage.getItem("tax_rate"))) /
                    100
                )
              : 0
          ).toFixed(2)
        ).toFixed(2)
      ) || Number(sessionStorage.getItem("plan_price"))
    
    sessionStorage.setItem("total_program_price", totalProgramPrice.toString() || "0")

    if (sessionStorage.getItem("plan_add_percent")) {
      const totalProgramPriceAddPercent = sessionStorage.getItem("plan_add_percent")
      ? +Number(
          (
            Number(totalProgramPrice) +
            Number(
              sessionStorage.getItem("plan_add_percent")
                ? Number((Number(totalProgramPrice) * Number(sessionStorage.getItem("plan_add_percent"))) / 100)
                : 0
            )
          ).toFixed(2)
        ).toFixed(2)
      : totalProgramPrice
      
      sessionStorage.setItem("total_program_price_add_percent", totalProgramPriceAddPercent.toString() || "0")
    }

    if (sessionStorage.getItem("module") === "LEADS") {
      
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/lead-conversion/check-payment`,
          {
            country: sessionStorage.getItem("country"),
            email: sessionStorage.getItem("email"),
            payment_type: paymentTypeSelectedItem,
          }
        )
        .then(async (res) => {
          sessionStorage.setItem("check_payment_msg", res.data.message)
          setCheckingPayment(false)

          switch (paymentTypeSelectedItem) {
            case "EFT":
              history.push("/payment-portal/eft-upload");
              break
            case "Card":
              history.push("/payment-portal/card-details");
              break
            case "Payment Link":
              history.push("/payment-portal/payment-link");
              break
            default:
              break
          }
        })
        .catch((err) => {
          setCheckPaymentSuccess("ERROR")
          sessionStorage.setItem("check_payment_msg", err.response.data.message)
          setCheckingPayment(false)
        })
    } else {   
      
      switch (paymentTypeSelectedItem) {
        case "EFT":
          history.push("/payment-portal/eft-upload");
          break
        case "Card":
          history.push("/payment-portal/existing-card");
          break
        case "Insurance":
          setInsuranceConfirmationModalOpen(true);
          break
        case "Payment Link":
          history.push("/payment-portal/payment-link");
          break
        default:
          break
      }
    }
  }
  
  

  useEffect(() => {
    setPaymentTypeSelectedItem("Select payment type")
    setCheckPaymentSuccess("")
    sessionStorage.removeItem("check_payment_msg")
    sessionStorage.removeItem("plan_add_percent")
    sessionStorage.removeItem("isDiscount")
  }, [])

  return (
    <Layout
      pageNumber={7}
      nextButtonDisabled={nextButtonDisabled}
      handleProceed={handleProceed}
      checkingPayment={checkingPayment}
    >
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <label
          htmlFor="payment_type"
          className="block text-sm font-medium text-gray-700"
        >
          Payment Type
        </label>
        <PaymentTypeSelectMenu
          paymentTypeList={paymentTypeList}
          paymentTypeSelectedItem={paymentTypeSelectedItem}
          setPaymentTypeSelectedItem={setPaymentTypeSelectedItem}
          setNextButtonDisabled={setNextButtonDisabled}
        />
        {sessionStorage.getItem("check_payment_msg") &&
          checkPaymentSuccess === "ERROR" && (
            <div className="flex flex-col">
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center mt-4">
                <span className="block sm:inline">
                  {sessionStorage.getItem("check_payment_msg")}
                </span>
              </div>
              <a
                className="self-center"
                href={`https://crm.zoho.com/crm/org771947106/tab/Leads/${sessionStorage.getItem(
                  "id"
                )}/convert?showPortalChange=false`}
                target="_blank"
                rel="noreferrer"
              >
                <button
                  type="button"
                  className={`px-4 mt-6 text-white bg-navy-theme w-72 h-10 text-center border border-transparent text-base font-semibold rounded-lg shadow-sm focus:outline-none focus:ring-0`}
                >
                  Convert Lead
                </button>
              </a>
            </div>
          )}
      </div>

      <InsuranceConfirmationModal
        insuranceConfirmationModalOpen={
          insuranceConfirmationModalOpen
        }
        setInsuranceConfirmationModalOpen={
          setInsuranceConfirmationModalOpen
        }
      />

    </Layout>
  )
}

export default PaymentType
