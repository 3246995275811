import { FC, useCallback, useEffect, useRef, ChangeEvent } from "react"
import { ReactComponent as Search } from "../../../../assets/common/search.svg"

type ProductPlan = {
  Books_Item_Id: string
  Description: string
  Product_Name: string
  Product_Type: string
  Unit_Price: number
  id: string
}
interface ProductPlanSearchMenuProps {
  searchPlans: () => void
  productPlanList: ProductPlan[]
  productPlanSelectedItem: string
  setProductPlanSelectedItem: (args: string) => void
  productPlanDropdownOpen: boolean
  setProductPlanDropdownOpen: (args: boolean) => void
  setIsProductPlanSelected: (args: boolean) => void
}

const ProductPlanSearchMenu: FC<ProductPlanSearchMenuProps> = ({
  searchPlans,
  productPlanList,
  productPlanSelectedItem,
  setProductPlanSelectedItem,
  productPlanDropdownOpen,
  setProductPlanDropdownOpen,
  setIsProductPlanSelected,
}) => {
  const dropDownRef = useRef<HTMLDivElement>(null)
  const openHandler = (): void => {
    if (productPlanSelectedItem !== "") {
      setProductPlanDropdownOpen(!productPlanDropdownOpen)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductPlanSelectedItem(e.target.value)
  }

  const selectPlanHandler = (id: string) => {
    const selected = productPlanList.find((el) => el.id === id)

    if (sessionStorage.getItem("country") === "CA" && sessionStorage.getItem("province") === "Quebec") {
      sessionStorage.setItem("plan_id", selected!.id)
      sessionStorage.setItem("plan_name", selected!.Product_Name)
      sessionStorage.setItem("plan_type", selected!.Product_Type)
      if (selected!.Product_Type !== "Medication") {
        sessionStorage.setItem("plan_price", selected!.Unit_Price.toString())
      }
    }
    else {
      sessionStorage.setItem("plan_id", selected!.id)
      sessionStorage.setItem("plan_name", selected!.Product_Name)
      sessionStorage.setItem("plan_type", selected!.Product_Type)
      sessionStorage.setItem("plan_price", selected!.Unit_Price.toString())
    }


    setIsProductPlanSelected(true)
    setProductPlanDropdownOpen(false)
    selected!.Product_Name.length > 45
      ? setProductPlanSelectedItem(selected!.Product_Name?.slice(0, 45) + "...")
      : setProductPlanSelectedItem(selected!.Product_Name)
  }

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (!dropDownRef.current?.contains(e.target as Node)) {
        setProductPlanDropdownOpen(false)
      }
    },
    [setProductPlanDropdownOpen]
  )

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick)
    return () => {
      document.body.removeEventListener("click", handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div className={`relative mt-2`}>
      <div
        className="bg-E0E0E0 h-12 w-full rounded-md flex border-c4c4c4 border"
        onClick={openHandler}
        ref={dropDownRef}
      >
        <div className="ml-4 flex items-center justify-center min-w-2.375 w-2.375 h-full">
          <Search />
        </div>
        <div>
          <button
            className="absolute top-3 right-6 font-medium"
            onClick={() => searchPlans()}
          >
            Search
          </button>
        </div>
        <input
          className="bg-E0E0E0 flex items-center pl-2 text-828282 w-full border border-transparent focus:outline-none focus:ring-0 font-medium"
          placeholder="Enter the name or type of product plan"
          value={productPlanSelectedItem}
          onChange={handleChange}
          onKeyPress={(event) => event.key === "Enter" && searchPlans()}
        />
        {productPlanDropdownOpen && (
          <div className="items absolute border rounded-md border-E0E0E0 bg-white shadow-sm top-11 w-full z-40">
            {productPlanList?.map((item, index) => (
              <div
                className={`${
                  index === 0 ? "" : "border-t border-F0F0F0"
                } cursor-pointer py-2 px-6 rounded-md text-navy-theme font-medium hover:bg-FF5733 hover:text-white`}
                key={index}
                onClick={() => {
                  selectPlanHandler(item.id)
                }}
              >
                {
                  <div className="flex text-sm">
                    <div className="w-64">{item.Product_Name}</div>
                    <div className="w-36">{item.Product_Type}</div>
                    <div className="w-36">
                      {(sessionStorage.getItem("product_type") !== "Medication") && (
                        <div className="w-36">
                          {sessionStorage.getItem("currency") === "EUR"
                            ? "€"
                            : sessionStorage.getItem("currency") === "GBP"
                            ? "£"
                            : "$"}
                          {item?.Unit_Price?.toFixed(2)}
                        </div>
                      )}
                    </div>
                  </div>
                }
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductPlanSearchMenu
