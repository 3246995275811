import { FC, Fragment, useState } from "react"
import { useHistory } from "react-router-dom"
import { Dialog, Transition } from "@headlessui/react"
import spinnerBlack from "../../../../../assets/common/spinnerBlack.svg"
import axios from "axios"
import { PaymentIntentResult, Stripe } from "@stripe/stripe-js"

interface UpdateCardConfirmationModalProps {
  updateCardConfirmationModalOpen: boolean
  setUpdateCardConfirmationModalOpen: (args: boolean) => void
  cardName: string | null
  cardNumber: string | null
  cardMonth: string | null
  cardYear: string | null
  cardCVC: string | null
  cardAmount: string
  stripe: Stripe | null | undefined
}

type Opportunity = {
  opportunity_id: string
  opportunity_name: string
  amount_paid: number
  total_price: number
  enquiry: string
  plan_info: {
    name: string
    id: string
  }
  currency: string
  last_four_digit: string
  brand: string
  invoice_number: string
  invoice_id: string
  name_on_card: string
}

const UpdateCardConfirmationModal: FC<UpdateCardConfirmationModalProps> = ({
  updateCardConfirmationModalOpen,
  setUpdateCardConfirmationModalOpen,
  cardName,
  cardNumber,
  cardMonth,
  cardYear,
  cardCVC,
  cardAmount,
  stripe,
}) => {
  const history = useHistory()
  const [processingPayment, setProcessingPayment] = useState(false)
  const [authenticatingUser, setAuthenticatingUser] = useState(false)
  const [confirmDisabled, setConfirmDisabled] = useState(false)

  const handleCancel = () => {
    setUpdateCardConfirmationModalOpen(false)
    setProcessingPayment(false)
  }

  const handlePayment = async () => {
    setProcessingPayment(true)
    setConfirmDisabled(true)

    if (
      sessionStorage.getItem("createOpportunitySkipped") === "Yes"  &&
      (sessionStorage.getItem("isCreateOpportunity") === "Yes" || sessionStorage.getItem("isRenewOpportunity") === "Yes")
    ) {

      if (
        sessionStorage.getItem("country") === "CA" &&
        sessionStorage.getItem("province") === "Quebec"
      ) {
        const opportunityResult = await axios
          .post(`${process.env.REACT_APP_API_URL}admin/v1/opportunity/create-quebec`, {
            country: sessionStorage.getItem("country"),
            contact_id: sessionStorage.getItem("id"),
            stage: sessionStorage.getItem("stage"),
            performance_manager: {
              name: sessionStorage.getItem("pm_name"),
              id: sessionStorage.getItem("pm_id"),
            },
            product_plan: sessionStorage.getItem("isPlanAddPercent") === "Yes" 
              ? {
                id: sessionStorage.getItem("plan_id"),
                name: sessionStorage.getItem("plan_name"),
                add_percent: sessionStorage.getItem("plan_add_percent"),
              }
              : {
                id: sessionStorage.getItem("plan_id"),
                name: sessionStorage.getItem("plan_name"),
              },
            program_start: sessionStorage.getItem("program_start_date"),
            program_end: sessionStorage.getItem("program_end_date"),
            terminal: sessionStorage.getItem("terminal"),
            currency: "CAD",
            province: sessionStorage.getItem("province"),
            pharmacy: sessionStorage.getItem("plan_type") === "Medication" ? {
              id: sessionStorage.getItem("pharmacy_id"),
              name: sessionStorage.getItem("pharmacy_name"),
              price: sessionStorage.getItem("plan_price"),
              medicine_count: sessionStorage.getItem("pharmacy_medicine_count"),
            } : null,
            old_opportunity_id: sessionStorage.getItem("opportunity_id"),
            is_renewal: sessionStorage.getItem("isRenewOpportunity") === "Yes" ? true : false,
          })
          .then(async (res) => {
            if (res.status === 200) {
              sessionStorage.setItem("opportunity_id", res.data.data.opportunity_id)
              return true
            }
          })
          .catch(async (err) => {
            await sessionStorage.setItem(
              "error",
              "Error in creating opportunity: " +
              err.response.data.message || err.response.data.data
            )
            history.push("/payment-error")
            return false
          })

        if (!opportunityResult) {
          return
        }
      }

      else {
        const opportunityResult = await axios
          .post(`${process.env.REACT_APP_API_URL}admin/v1/opportunity/create`, {
            country: sessionStorage.getItem("country"),
            contact_id: sessionStorage.getItem("id"),
            stage: sessionStorage.getItem("stage"),
            performance_manager: {
              name: sessionStorage.getItem("pm_name"),
              id: sessionStorage.getItem("pm_id"),
            },
            product_plan: sessionStorage.getItem("isPlanAddPercent") === "Yes" 
              ? {
                id: sessionStorage.getItem("plan_id"),
                name: sessionStorage.getItem("plan_name"),
                add_percent: sessionStorage.getItem("plan_add_percent"),
              }
              : {
                id: sessionStorage.getItem("plan_id"),
                name: sessionStorage.getItem("plan_name"),
              },
            program_start: sessionStorage.getItem("program_start_date"),
            program_end: sessionStorage.getItem("program_end_date"),
            terminal: sessionStorage.getItem("terminal"),
            old_opportunity_id: sessionStorage.getItem("opportunity_id"),
            is_renewal: sessionStorage.getItem("isRenewOpportunity") === "Yes" ? true : false,
            ...(sessionStorage.getItem("country") === "UK" && { currency: sessionStorage.getItem("currency") }),
            ...(sessionStorage.getItem("country") === "CA" && { province: sessionStorage.getItem("province") }),
          })
          .then(async (res) => {
            if (res.status === 200) {
              sessionStorage.setItem("opportunity_id", res.data.data.opportunity_id)
              return true
            }
          })
          .catch(async (err) => {
            await sessionStorage.setItem(
              "error",
              "Error in creating opportunity: " +
              err.response.data.message || err.response.data.data
            )
            history.push("/payment-error")
            return false
          })
        
        if (!opportunityResult) {
          return
        }
      }
    }

    if (
      sessionStorage.getItem("createOpportunitySkipped") === "Yes" &&
      sessionStorage.getItem("isUpgradeOpportunity") === "Yes"
    ) {
      const opportunityResult = await axios
        .post(`${process.env.REACT_APP_API_URL}admin/v1/opportunity/upgrade`, {
          country: sessionStorage.getItem("country"),
          contact_id: sessionStorage.getItem("contact_id"),
          opportunity_id: sessionStorage.getItem("opportunity_id"),
          stage: sessionStorage.getItem("stage"),
          product_plan: sessionStorage.getItem("isPlanAddPercent") === "Yes" 
            ? {
              id: sessionStorage.getItem("plan_id"),
              name: sessionStorage.getItem("plan_name"),
              add_percent: sessionStorage.getItem("plan_add_percent"),
            }
            : {
              id: sessionStorage.getItem("plan_id"),
              name: sessionStorage.getItem("plan_name"),
            },
          program_start: sessionStorage.getItem("program_start_date"),
          program_end: sessionStorage.getItem("program_end_date"),
          ...(sessionStorage.getItem("country") === "UK" && { currency: sessionStorage.getItem("currency") }),
          ...(sessionStorage.getItem("country") === "CA" && { province: sessionStorage.getItem("province") }),
        })
        .then(async (res) => {
          if (res.status === 200) {
            sessionStorage.setItem("opportunity_id", res.data.data.opportunity_id)
            return true
          }
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            "Error in upgrading opportunity: " +
            err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
          return false
        })

      if (!opportunityResult) {
        return
      }
    }

    let potentialErrorInfo = ""
    if (sessionStorage.getItem("isCreateOpportunity") === "Yes") {
      if (sessionStorage.getItem("isCreateOpportunity") === "Yes") {
        potentialErrorInfo = "Opportunity created, error in payment collection: "
      } 
      if (sessionStorage.getItem("isUpgradeOpportunity") === "Yes") {
        potentialErrorInfo = "Opportunity upgraded, error in payment collection: "
      }
    }

    if (sessionStorage.getItem("country") == "PCA") {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/collect-payment-pca`,
          {
            country: sessionStorage.getItem("country"),
            amount: Number(cardAmount),
            note: sessionStorage.getItem("note"),
            email: sessionStorage.getItem("email"),
            card_name: sessionStorage.getItem("card_name"),
            module: sessionStorage.getItem("module"),
            opportunity_id: sessionStorage.getItem("opportunity_id"),
            contact_id: sessionStorage.getItem("id"),
            transaction_type: sessionStorage.getItem("transaction_type"),
            is_discount: sessionStorage.getItem("isDiscount") === "Yes" ? true : false,
          }
        )
        .then(async () => {
          history.push("/payment-success")
          return
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
        })
      return
    }

    else if (
      sessionStorage.getItem("country") === "CA" &&
      sessionStorage.getItem("province") === "Quebec" &&
      sessionStorage.getItem("isCreateOpportunity") === "Yes"
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/collect-payment-quebec`,
          {
            country: sessionStorage.getItem("country"),
            amount: (sessionStorage.getItem("isCreateOpportunity") === "Yes")
              ? Number(sessionStorage.getItem("initial_deposit"))
              : Number(cardAmount),
            note: sessionStorage.getItem("note") ? sessionStorage.getItem("note") : "None", //not getting note for new opportunity
            email: sessionStorage.getItem("email"),
            card_name: sessionStorage.getItem("card_name"),
            module: sessionStorage.getItem("module"),
            opportunity_id: sessionStorage.getItem("opportunity_id"),
            contact_id: sessionStorage.getItem("id"),
            transaction_type: sessionStorage.getItem("transaction_type"),
            product_plan: sessionStorage.getItem("transaction_type") === "Upgrade" ? {
              name: sessionStorage.getItem("plan_name"),
              id: sessionStorage.getItem("plan_id"),
            } : null,
            recurring_payment: sessionStorage.getItem("isRecurring") === "Yes" ? {
              recurring_amount: Number(
                sessionStorage.getItem("recurring_amount")
              ),
              interval: sessionStorage.getItem("interval"),
              interval_count: Number(
                sessionStorage.getItem("interval_count")
              ),
              start_date: formattedRecurringStartDate,
              end_date: formattedRecurringEndDate,
              frequency: sessionStorage.getItem("frequency"),
              recurring_iteration: Number(
                sessionStorage.getItem("recurring_iteration")
              ),
            } : null,
            scheduled_payment: sessionStorage.getItem("isScheduledDeposit") === "Yes" ? {
              amount: Number(
                sessionStorage.getItem("scheduled_deposit_amount")
              ),
              date: formattedScheduledDepositDate,
            } : null,
            is_discount: sessionStorage.getItem("isDiscount") === "Yes" ? true : false,
          }
        )
        .then(async () => {
          history.push("/payment-success")
          return
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
        })
    }

    else if (
      sessionStorage.getItem("country") === "CA" &&
      sessionStorage.getItem("transaction_type") === "Upgrade"
    ) {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/collect-payment`,
          {
            country: sessionStorage.getItem("country"),
            amount: Number(cardAmount),
            note: sessionStorage.getItem("note"),
            email: sessionStorage.getItem("email"),
            card_name: sessionStorage.getItem("card_name"),
            module: sessionStorage.getItem("module"),
            opportunity_id: sessionStorage.getItem("opportunity_id"),
            contact_id: sessionStorage.getItem("id"),
            transaction_type: sessionStorage.getItem("transaction_type"),
            product_plan: {
              name: sessionStorage.getItem("plan_name"),
              id: sessionStorage.getItem("plan_id"),
            },
            is_discount: sessionStorage.getItem("isDiscount") === "Yes" ? true : false,
          }
        )
        .then(async () => {
          await handleInvoice()
          history.push("/payment-success")
          return
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
        })
      return
    } else {

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/collect-payment`,
          {
            country: sessionStorage.getItem("country"),
            amount: Number(cardAmount),
            note: sessionStorage.getItem("note"),
            email: sessionStorage.getItem("email"),
            card_name: cardName,
            module: sessionStorage.getItem("module"),
            opportunity_id: sessionStorage.getItem("opportunity_id"),
            contact_id: sessionStorage.getItem("id"),
            transaction_type: sessionStorage.getItem("transaction_type"),
            is_discount: sessionStorage.getItem("isDiscount") === "Yes" ? true : false,
          }
        )
        .then(async (res) => {
          sessionStorage.setItem("last_four_digits", cardNumber!.substr(-4))

          if (res.status === 200 && res.data.data.need_confirmation) {
            setProcessingPayment(false)
            setAuthenticatingUser(true)

            if (stripe) {
              stripe
                .confirmCardPayment(res.data.data.client_secret)
                .then(async (res: PaymentIntentResult) => {
                  if (res.error) {
                    await sessionStorage.setItem(
                      "error",
                      potentialErrorInfo + res.error.message as string
                    )
                    history.push("/payment-error")
                    return
                  }

                  setAuthenticatingUser(false)
                  setProcessingPayment(true)

                  await axios
                    .post(
                      `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/collect-payment`,
                      {
                        country: sessionStorage.getItem("country"),
                        amount: Number(cardAmount),
                        note: sessionStorage.getItem("note"),
                        email: sessionStorage.getItem("email"),
                        card_name: sessionStorage.getItem("card_name"),
                        module: sessionStorage.getItem("module"),
                        opportunity_id:
                          sessionStorage.getItem("opportunity_id"),
                        contact_id: sessionStorage.getItem("id"),
                        transaction_type:
                          sessionStorage.getItem("transaction_type"),
                        stripe_confirmation: {
                          id: res.paymentIntent.id,
                          amount: res.paymentIntent.amount,
                          capture_method: res.paymentIntent.capture_method,
                          created: res.paymentIntent.created,
                          currency: res.paymentIntent.currency,
                          description: res.paymentIntent.description,
                          payment_method: res.paymentIntent.payment_method,
                          receipt_email: res.paymentIntent.receipt_email,
                          status: res.paymentIntent.status,
                        },
                      }
                    )
                    .then(async (res) => {
                      if (res.status === 200) {
                        if (
                          sessionStorage.getItem("transaction_type") !==
                            "Upgrade" &&
                          sessionStorage.getItem("country") !== "UK" &&
                          sessionStorage.getItem("country") !== "NZ"
                        ) {
                          await handleInvoice()
                        }
                        history.push("/payment-success")
                        return
                      }
                    })
                    .catch(async (err) => {
                      await sessionStorage.setItem(
                        "error",
                        potentialErrorInfo + err.response.data.message || err.response.data.data
                      )
                      history.push("/payment-error")
                    })
                })
            }
            return
          }

          if (res.status === 200) {
            if (
              sessionStorage.getItem("transaction_type") !== "Upgrade" &&
              sessionStorage.getItem("country") !== "UK" &&
              sessionStorage.getItem("country") !== "NZ"
            ) {
              await handleInvoice()
            }
            history.push("/payment-success")
            return
          }
        })
        .catch(async (err) => {
          await sessionStorage.setItem(
            "error",
            potentialErrorInfo + err.response.data.message || err.response.data.data
          )
          history.push("/payment-error")
        })

      return
    }
  }

  const handleInvoice = async () => {
    if (sessionStorage.getItem("invoice_id")) {
      await axios
        .post(`${process.env.REACT_APP_API_URL}admin/v1/invoice/send`, {
          email: sessionStorage.getItem("email"),
          country: sessionStorage.getItem("country"),
          invoice_id: sessionStorage.getItem("invoice_id"),
        })
        .then(() => {
          sessionStorage.setItem("invoice_status", "SUCCESS")
        })
        .catch((err) => {
          sessionStorage.setItem("invoice_status", "ERROR")
          sessionStorage.setItem("invoice_message", err.response.data.message)
        })
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}admin/v1/payment-collection/get-opportunity`,
          {
            contact_id: sessionStorage.getItem("id"),
            country: sessionStorage.getItem("country"),
          }
        )
        .then(async (res) => {
          await sessionStorage.setItem(
            "invoice_id",
            res?.data?.data.filter(
              (opp: Opportunity) =>
                opp?.opportunity_id === sessionStorage.getItem("opportunity_id")
            )[0]?.invoice_id
          )

          await axios
            .post(`${process.env.REACT_APP_API_URL}admin/v1/invoice/send`, {
              email: sessionStorage.getItem("email"),
              country: sessionStorage.getItem("country"),
              invoice_id: sessionStorage.getItem("invoice_id"),
            })
            .then(() => {
              sessionStorage.setItem("invoice_status", "SUCCESS")
            })
            .catch((err) => {
              sessionStorage.setItem("invoice_status", "ERROR")
              sessionStorage.setItem(
                "invoice_message",
                err.response.data.message
              )
            })
        })
        .catch(() => {
          sessionStorage.setItem("invoice_status", "ERROR")
          sessionStorage.setItem(
            "invoice_message",
            "Could not retrieve Invoice ID"
          )
        })
    }
  }

  const recurringStartDate =
    sessionStorage.getItem("recurring_start_date") || ""
  const [year2, month2, day2]: string[] = recurringStartDate.split("-")
  const formattedRecurringStartDate = [day2, month2, year2].join("/")

  const recurringEndDate = sessionStorage.getItem("recurring_end_date") || ""
  const [year3, month3, day3]: string[] = recurringEndDate.split("-")
  const formattedRecurringEndDate = [day3, month3, year3].join("/")

  const scheduledDepositDate =
    sessionStorage.getItem("scheduled_deposit_date") || ""
  const [year4, month4, day4]: string[] = scheduledDepositDate.split("-")
  const formattedScheduledDepositDate = [day4, month4, year4].join("/")

  return (
    <Transition.Root show={updateCardConfirmationModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={setUpdateCardConfirmationModalOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:min-w-642 sm:p-6 w-800">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-xl leading-6 font-medium text-gray-900"
                  >
                    Confirm Payment Details{" "}
                    {sessionStorage.getItem("createOpportunitySkipped") === "Yes" && sessionStorage.getItem("isCreateOpportunity") === "Yes" && "and Create Opportunity"}
                    {sessionStorage.getItem("createOpportunitySkipped") === "Yes" && sessionStorage.getItem("isUpgradeOpportunity") === "Yes" && "and Upgrade Opportunity"}
                    {sessionStorage.getItem("createOpportunitySkipped") === "Yes" && sessionStorage.getItem("isRenewOpportunity") === "Yes" && "and Renew Opportunity"}
                  </Dialog.Title>
                </div>
              </div>

              <div className="w-full sm:w-full mt-7 mb-2">
                <dl>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      {sessionStorage.getItem("isRecurring") === "Yes" ||
                      sessionStorage.getItem("isScheduledDeposit") === "Yes"
                        ? "Initial Deposit"
                        : "Payment Amount"}
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      $
                      {sessionStorage.getItem("amount") &&
                        Number(sessionStorage.getItem("amount")).toFixed(2)}
                      {sessionStorage.getItem("initial_deposit") &&
                        Number(
                          sessionStorage.getItem("initial_deposit")
                        ).toFixed(2)}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Card Number
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("card_number")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Cardholder Name
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {cardName}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Expiration Date
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {cardMonth} / {cardYear}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Card CVV
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {cardCVC}
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="w-full sm:w-full mt-12 mb-2">
                <dl>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Customer Name
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("full_name")}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Lead ID
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("id")}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Email
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("email")}
                    </dd>
                  </div>
                  <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Mobile
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("mobile") &&
                      sessionStorage.getItem("mobile") !== "null"
                        ? sessionStorage.getItem("mobile")
                        : "N/A"}
                    </dd>
                  </div>
                  <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                    <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                      Transaction Type
                    </dt>
                    <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                      {sessionStorage.getItem("transaction_type")}
                    </dd>
                  </div>
                  {(sessionStorage.getItem("province") !== "Quebec" && sessionStorage.getItem("isCreateOpportunity") !== "Yes") && (
                    <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                      <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                        Description
                      </dt>
                      <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                        {sessionStorage.getItem("note")}
                      </dd>
                    </div>
                  )}
                </dl>
              </div>

              <div className="w-full sm:w-full mt-12 mb-2">
                <dl>
                  {sessionStorage.getItem("isScheduledDeposit") === "Yes" && (
                    <>
                      <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                          Scheduled Deposit
                        </dt>
                        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                          $
                          {Number(
                            sessionStorage.getItem("scheduled_deposit_amount")
                          ).toFixed(2)}
                        </dd>
                      </div>
                      <div className=" px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                          Scheduled Deposit Date
                        </dt>
                        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                          {formattedScheduledDepositDate}
                        </dd>
                      </div>
                    </>
                  )}
                  {sessionStorage.getItem("isRecurring") === "Yes" && (
                    <>
                      <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                          Recurring Payment Plan
                        </dt>
                        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                          $
                          {Number(
                            sessionStorage.getItem("recurring_amount")
                          ).toFixed(2)}{" "}
                          {sessionStorage.getItem("frequency")}
                        </dd>
                      </div>
                      <div className="px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                        <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                          Recurring Payment Start Date
                        </dt>
                        <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                          {formattedRecurringStartDate}
                        </dd>
                      </div>
                      {sessionStorage.getItem("forever_flag") === "true" ? (
                        <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                          <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                            Recurring Payment Forever
                          </dt>
                          <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                            True
                          </dd>
                        </div>
                      ) : (
                        <div className="bg-c4c4c4-20 px-4 py-2 sm:grid sm:grid-cols-9 sm:px-6">
                          <dt className="text-sm font-normal text-828282 sm:col-start-3 sm:col-span-2">
                            Recurring Payment End Date
                          </dt>
                          <dd className="ml-4 mt-1 text-sm text-828282 sm:mt-0 sm:col-span-3 font-semibold">
                            {formattedRecurringEndDate}
                          </dd>
                        </div>
                      )}
                    </>
                  )}
                </dl>
              </div>

              <div className="flex flex-col sm:px-6 lg:px-8 px-5 relative">
                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                  <div className="mt-5 sm:mt-6 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center w-20 rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-gray-900 focus:outline-none sm:text-sm mx-4"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                    <div className="relative">
                      {(processingPayment || authenticatingUser) && (
                        <img
                          width={40}
                          className="inline absolute left-1"
                          src={spinnerBlack}
                          alt="Loading..."
                        />
                      )}
                      <button
                        type="button"
                        className={`${
                          processingPayment || authenticatingUser
                            ? "bg-gray-300 hover:bg-gray-300 cursor-auto"
                            : "bg-FF5733 hover:bg-FF5733_hover text-white"
                        } inline-flex justify-center w-200 rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none sm:text-sm`}
                        onClick={() => {
                          if (!confirmDisabled) {
                            handlePayment()
                          }
                        }}
                      >
                        {processingPayment
                          ? "Processing..."
                          : authenticatingUser
                          ? "Authenticating..."
                          : "Confirm Payment"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpdateCardConfirmationModal
